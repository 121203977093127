import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import Radio from '../../../../../UI/Field/Radio/RadioField';
import Selector from '../../../../../UI/Field/Selector/SelectorField';
import Grid24 from '../../../../../UI/Grid24';
import Faixa from './Faixa';

import { Container, GroupDefinitions, AddContainer } from './styles';

import { useCrudClienteSelector, useCrudClienteDispatch } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { getRequiredLabel } from '../../../../../../helpers/utils/utils';
import constants from './constants';
import {
  setHonorarioDefinitionAction,
  updateHonorarioAction,
  addHonorarioAction,
  removeHonorarioAction,
  updateTipoCalculoHonorarioAction,
} from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { findFasesAction, findTipoCalculoHonorarioAction } from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import { SubTitleWrapperForm } from '../../../../../Paramentros/Crud/Tabs/ContasBancarias/TabContasBancarias/ContasBancarias.styles';
import FaixasHonorariosFaseCliente from './FaixaFaseCliente';


function Honorarios({ location, disabled }) {
  const globalDispatch = useDispatch();
  const dispatch = useCrudClienteDispatch();
  const utilStyles = useUtilStyles();

  const tiposCalculoHonorarioComboItens = useSelector(
    states => states.selectors.tipoCalculoHonorarioParametro.selector,
  );
  const definition = useCrudClienteSelector(states => states.honorarioDefinition);
  const tipoCalculoHonorario = useCrudClienteSelector(states => states.controls.tipoCalculoHonorarios);
  const fases = useSelector(state => state.selectors.fases.selector);

  useEffect(() => {
    globalDispatch(findTipoCalculoHonorarioAction());
    globalDispatch(findFasesAction());
  }, [globalDispatch, location.refreshPage]);


  const onChangeDefinitionHandler = (e) => {
    const { value } = e.target;
    const { id, value: description } = tiposCalculoHonorarioComboItens.filter(tipo => tipo.id === 2)[0];
    dispatch(setHonorarioDefinitionAction(id, description, value, fases));
  };


  const onChangeTipoHonorarioHandler = useCallback((e) => {
    const { value } = e.target;
    const { id, value: description } = tiposCalculoHonorarioComboItens.filter(tipo => tipo.id === value)[0];
    dispatch(updateTipoCalculoHonorarioAction(id, description));
  }, [dispatch, tiposCalculoHonorarioComboItens]);


  const onChangeHonorarioHandler = useCallback((e) => {
    const { name: fullname, value } = e.target;
    const [name, idx] = fullname.split('-');
    dispatch(updateHonorarioAction(name, value, idx));
  }, [dispatch]);


  const onFocusHonorarioHonorarioHandler = useCallback((e) => {
    const { name: fullname } = e.target;
    const [name, idx] = fullname.split('-');
    dispatch(updateHonorarioAction(name, '', idx, 'focus'));
  }, [dispatch]);


  const onChangeRemoveHonorarioRangeHandler = useCallback((e) => {
    const idxHonorario = +e.currentTarget.getAttribute('idxHonorario');
    dispatch(removeHonorarioAction(idxHonorario));
  }, [dispatch]);


  const onAddHonorarioRangeHandler = useCallback(() => {
    dispatch(addHonorarioAction(fases));
  }, [dispatch, fases]);

  return (
    <FormWrapperWithTitle title={constants.title}>
      <Container container isFormContainer>

        <GroupDefinitions
          aria-label="honorarios-definicoes"
          value={definition}
          onChange={onChangeDefinitionHandler}

        >
          <Radio disabled={disabled} label={constants.abeDefinitions} value="abe" />
          <Radio disabled={disabled} label={constants.clientDefinitions} value="cliente" />
        </GroupDefinitions>

        {tipoCalculoHonorario && (
          <>
            <Grid24 xs={24} sm={6}>
              <Selector
                isForm
                disabled={disabled}
                label={getRequiredLabel(constants.comboboxCalculationType, !disabled)}
                value={tipoCalculoHonorario.idTipoCalculoHonorario.value}
                items={tiposCalculoHonorarioComboItens}
                onChange={onChangeTipoHonorarioHandler}
              />
            </Grid24>

            <Grid24>
              <AddContainer
                title={constants.addTitle + tipoCalculoHonorario.descricaoTipoCalculoHonorario.value}
                readTitle={constants.addTitleForQuery + tipoCalculoHonorario.descricaoTipoCalculoHonorario.value}
                disabled={disabled}
                addHandler={onAddHonorarioRangeHandler}
                tooltip={constants.addHint}
                readMode={disabled}
              >
                {tipoCalculoHonorario.honorarios.map((honorario, idx) => (
                  <div key={honorario.key.value}>
                    <Faixa
                      key={honorario.key.value}
                      type={tipoCalculoHonorario.type.value}
                      initial={honorario.valorFaixaInicial}
                      final={honorario.valorFaixaFinal}
                      rate={honorario.porcentagemHonorario}
                      showTrash={idx === 0}
                      disabled={disabled}
                      idxHonorario={idx}
                      onChange={onChangeHonorarioHandler}
                      onFocus={onFocusHonorarioHonorarioHandler}
                      onRemove={onChangeRemoveHonorarioRangeHandler}
                    />
                    {idx !== tipoCalculoHonorario.honorarios.length - 1 && (
                    <hr className={utilStyles.faixas__divider} />
                    )}
                  </div>
                ))}
              </AddContainer>
            </Grid24>
            <SubTitleWrapperForm title={constants.taxaHonorariosFaseTitle}>
              <FaixasHonorariosFaseCliente tipoCalculoHonorario={tipoCalculoHonorario} disabled={disabled} />
            </SubTitleWrapperForm>
          </>
        )}

      </Container>
    </FormWrapperWithTitle>
  );
}


export default withRouter(Honorarios);
