import { gateway, gatewayImage } from '../../api';

const CORE = process.env.REACT_APP_CORE;

export function getParametros() {
  return gateway.get(`${CORE}/parametros`);
}

export function insertParametros(payload) {
  return gateway.put(`${CORE}/parametros`, payload);
}

export function getTipoCalculoHonorario() {
  return gateway.get(`${CORE}/tipos-calculo/honorarios`);
}

export function getTipoCalculoComissao() {
  return gateway.get(`${CORE}/tipos-calculo/comissoes`);
}

export function getRegraCalculoRepasse() {
  return gateway.get(`${CORE}/tipos-calculo/repasses/regras`);
}

export function getParametrosInterno() {
  return gateway.get(`${CORE}/parametros/interno`);
}

export function getTipoPrazoExpiracaoPix() {
  return gateway.get(`${CORE}/tipo-expiracao-pix`);
}


export function saveLogotipo(image) {
  const data = new FormData();
  data.append('logoAcordoSeguro', image);
  return gateway.post(`${CORE}/parametros/foto`, data);
}

export function obterLogotipo(format = 'BASE_64') {
  return gatewayImage.get(`${CORE}/parametros/foto`, { params: { format } });
}

export function obterArquivoQuitacaoDevedor(format = 'BASE_64') {
  return gateway.get(`${CORE}/parametros/arquivo-quitacao-devedor`, { params: { format } });
}

export function saveQuitacaoDevedorFile(arquivo) {
  const data = new FormData();
  data.append('arquivoQuitacaoDevedor', arquivo);
  return gateway.post(`${CORE}/parametros/arquivo-quitacao-devedor`, data);
}


export function getTipoRegraComissionamento() {
  return gateway.get(`${CORE}/tipos-comissao-comercial`);
}
