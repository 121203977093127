import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { CampoObrigatorio, InputField } from '../../../../components';
import Grid24 from '../../../../components/UI/Grid24';
import {
  extractResponseErrorMessage,
  getFieldName, getRequiredLabel, isBadRequestException, makeErrorField,
} from '../../../../helpers';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';
import { dialogReciboController } from './dialogReciboController';


function DialogRecibo({
  setOpenDialogRecibo, updatePage, setLoadings, idAcordo, tipoFormaPagtoDialogRecibo, nroParcelaDialogRecibo,
}) {
  const [formFields, setFormFields] = useState(dialogReciboController.makeFormFields());
  const [errorFields, setErrorFields] = useState(dialogReciboController.makeErrorFields());

  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: { ...oldValue[name], value } }));
  }, []);

  const onFocusHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: makeErrorField() }));
  }, []);

  const onClose = useCallback(() => {
    setOpenDialogRecibo(false);
    setFormFields(dialogReciboController.makeFormFields());
    setErrorFields(dialogReciboController.makeErrorFields());
  }, [setOpenDialogRecibo]);

  async function onClickSubmitEmail(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      const response = await dialogReciboController.enviarEmails({ idAcordo, nroParcelaDialogRecibo, tipoFormaPagtoDialogRecibo }, formFields);
      switch (response.data?.codResult) {
        case 0:
          toast.success('E-mail enviado com sucesso.');
          updatePage();
          onClose();
          break;
        case 1:
          toast.error(response.data?.msgResult, { style: { width: '392px' } });
          errorFields.emailList.error = true;
          errorFields.emailList.errorMessage = 'E-mail inválido';
          break;
        default:
          break;
      }
    } catch (error) {
      toast.error(error?.response?.data?.error, { style: { width: '392px' } });
      const validations = error?.response?.data?.validations;
      validations.forEach((validation) => {
        setErrorFields((errFields) => {
          errFields[validation.field].error = true;
          errFields[validation.field].errorMessage = validation.message;
          return errFields;
        });
      });
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    }
  }

  useEffect(() => {
    dialogReciboController.buscarEmailsPadraoDevedorAcordo(idAcordo).then((response) => {
      const email = response.data;
      setFormFields((forms) => {
        forms.emailList.value = email;
        return forms;
      });
    }).catch((error) => {
      if (isBadRequestException(error)) {
        toast.error('Ocorreu um erro ao comunicar com o servidor para obter o email.', { style: { width: '392px' } });
      }
      const erroMessage = extractResponseErrorMessage(error);
      if (erroMessage !== 'Erro não mapeado') {
        toast.error(erroMessage, { style: { width: '392px' } });
      }
    }).finally(() => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    });
  }, [idAcordo, setLoadings]);

  return (
    <>
      {errorFields?.emailList.error === true ? (
        <CampoObrigatorio />
      ) : (
        ''
      )}
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} style={{ marginBottom: '15px' }}>
          <InputField
            isForm
            fullWidth
            autoComplete="off"
            label={getRequiredLabel('Para', true)}
            name={formFields.emailList.name}
            value={formFields.emailList.value}
            error={errorFields.emailList.error}
            errorMessage={errorFields.emailList.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSubmitEmail}
        >
          Enviar
        </Button>
      </div>

    </>
  );
}


export default DialogRecibo;
