export default {
  title: 'Honorários',
  abeDefinitions: 'Utilizar Honorários definidos pela ABE',
  clientDefinitions: 'Utilizar Honorários definidos pelo Cliente ',
  comboboxCalculationType: 'Tipo de Cálculo para os Honorários',
  addTitle: 'Adicionar Faixa de Honorários por ',
  addTitleForQuery: 'Faixa(s) de Honorários por ',
  addHint: 'Adicionar Faixa',
  taxaHonorarioFaseTitle: 'Taxa Mínima de Honorários por Fase',
};
