import React from 'react';
import PrivateRoute from '../hoc/PrivateRoute/PrivateRoute';
import { ConsultaDinamicaPage } from '../pages';
import RegistrarBoletoPage from '../pages/RegistrarBoletos/RegistrarBoletoPage';

function RoutesFerramentasComponents() {
  return (
    <>
      <PrivateRoute
        path="/ferramentas/consulta-dinamica"
        tipoUsuario="U"
        roles={['1301']}
        exact
        component={ConsultaDinamicaPage}
      />
      <PrivateRoute
        path="/ferramentas/registrar-boleto"
        tipoUsuario="U"
        roles={['2101']}
        exact
        component={RegistrarBoletoPage}
      />
    </>
  );
}

export const RoutesFerramentas = RoutesFerramentasComponents;
