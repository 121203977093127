import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../../UI/Field/Input/InputField';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import Grid24 from '../../../../UI/Grid24/Grid24';
import { onChangeControls, onFocusControls } from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { toBrazilianDate } from '../../../../../helpers/utils/utils';
import ProcessamentoBordero from './ProcessamentoBordero/ProcessamentoBordero';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import {
  CHECKBOX_AJUIZAR_PROCESSOS,
  CHECKBOX_CALCULAR_JUROS_UNIUCO_LABEL, CHECKBOX_PERMITIR_UNIFICAR_PROCESSO_LABEL, CHECKBOX_PROCESSOS_COBRANCA_PRESENCIAL_LABEL, INTERVALO_ENTRE_PAGAMENTOS,
} from './ProcessamentoBordero/ProcessamentoCheckBox/processamentoCheckbox.constants';
import CheckboxField from '../../../../UI/Field/Checkbox/CheckboxField';
import SelectorField from '../../../../UI/Field/Selector/SelectorField';
import { findIntervaloPagamentoAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';


const CONSTANTS = {
  DATA_CADASTRAMENTO: 'Data de Cadastramento',
  DATA_ATIVACAO: 'Data de Ativação',
};

function DadosComplementares({
  isEditMode,
}) {
  const globalDispatch = useDispatch();
  const dispatch = useCrudClienteDispatch();
  const utilStyle = useUtilStyles();

  const dataCadastramento = useCrudClienteSelector(states => states.controls.dataCadastramento);
  const dataAtivacao = useCrudClienteSelector(states => states.controls.dataAtivacao);
  const calcularJurosUnico = useCrudClienteSelector(states => states.controls.calcularJurosUnico.copy());
  const permiteCobrancaPresencial = useCrudClienteSelector(states => states.controls.permiteCobrancaPresencial.copy());
  const permitirUnificarProcesso = useCrudClienteSelector(states => states.controls.permitirUnificarProcesso.copy());
  const permitirAjuizarProcessos = useCrudClienteSelector(states => states.controls.permitirAjuizarProcessos.copy());
  const intervaloPagamentosSelector = useSelector(states => states.selectors.intervaloPagamento.selector);
  const idIntervaloPagamento = useCrudClienteSelector(states => states.controls.idIntervaloPagamento.copy());

  useEffect(() => {
    globalDispatch(findIntervaloPagamentoAction());
  }, [globalDispatch]);

  const onChangeDadosComplementares = useCallback(
    (event) => {
      const { name, value } = event.target;
      dispatch(onChangeControls(name, value));
    },
    [dispatch],
  );

  const onFocusDadosComplementares = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(onFocusControls(fieldName));
  }, [dispatch]);

  const onChangeChecked = useCallback((event) => {
    const { name, checked } = event.target;
    dispatch(onChangeControls(name, checked));
  }, [dispatch]);

  const onChangeIntervaloPagamento = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeControls(name, value));
  }, [dispatch]);

  return (
    <>
      <Grid24 container isFormContainer spacing={1}>
        <Grid24 item xs={24} sm={3}>
          <InputField
            fullWidth
            isForm
            disabled
            label={CONSTANTS.DATA_CADASTRAMENTO}
            name={dataCadastramento.name}
            value={toBrazilianDate(dataCadastramento.value)}
          />
        </Grid24>
        <Grid24 item xs={24} sm={3}>
          <InputField
            fullWidth
            isForm
            disabled
            label={CONSTANTS.DATA_ATIVACAO}
            name={dataAtivacao.name}
            value={toBrazilianDate(dataAtivacao.value)}
          />
        </Grid24>
        <Grid24
          xs={24}
          sm={13}
          style={{ marginLeft: '44px', marginTop: '10px' }}
        >
          <Grid24 xs={24} sm={6}>
            <CheckboxField
              label={CHECKBOX_CALCULAR_JUROS_UNIUCO_LABEL}
              name={calcularJurosUnico.name}
              checked={calcularJurosUnico.value}
              onChange={onChangeChecked}
              disabled={!isEditMode}
            />
          </Grid24>
          <Grid24 xs={24} sm={6}>
            <CheckboxField
              label={CHECKBOX_PERMITIR_UNIFICAR_PROCESSO_LABEL}
              name={permitirUnificarProcesso.name}
              checked={permitirUnificarProcesso.value}
              onChange={onChangeChecked}
              disabled={!isEditMode}
            />
          </Grid24>
          <Grid24 xs={24} sm={6}>
            <CheckboxField
              label={CHECKBOX_PROCESSOS_COBRANCA_PRESENCIAL_LABEL}
              name={permiteCobrancaPresencial.name}
              checked={permiteCobrancaPresencial.value}
              onChange={onChangeChecked}
              disabled={!isEditMode}
            />
          </Grid24>
          <Grid24 xs={24} sm={6}>
            <CheckboxField
              label={CHECKBOX_AJUIZAR_PROCESSOS}
              name={permitirAjuizarProcessos.name}
              checked={permitirAjuizarProcessos.value}
              onChange={onChangeChecked}
              disabled={!isEditMode}
            />
          </Grid24>
        </Grid24>
      </Grid24>
      <Grid24 xs={24} sm={7} style={{ marginTop: '20px' }}>
        <SelectorField
          isForm
          items={intervaloPagamentosSelector}
          name={idIntervaloPagamento.name}
          value={idIntervaloPagamento.value}
          label={INTERVALO_ENTRE_PAGAMENTOS}
          hasEmptyLabel
          onChange={onChangeIntervaloPagamento}
          disabled={!isEditMode}
        />
      </Grid24>
      <Grid24 isFormContainer container spacing="1" className={utilStyle.mt16}>
        <Grid24 xs={24} sm={24}>
          <ProcessamentoBordero
            onChangeInputFields={onChangeDadosComplementares}
            onFocus={onFocusDadosComplementares}
            disabled={!isEditMode}
            isEditMode={isEditMode}
            onChangeChecked={onChangeChecked}
          />
        </Grid24>
      </Grid24>
    </>
  );
}

export default withRouter(DadosComplementares);
