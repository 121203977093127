import { v1 as uuidv1 } from 'uuid';
import FieldModel from '../../models/FieldModel';
import { getAttributeExistingObject, getExistingFieldModel } from '../utils/utils';
import { tipoCalculoEnum } from '../constants/enums.constants';

export function buildClienteBordero(cliente) {
  return {
    idCliente: new FieldModel({
      name: 'idCliente', value: getAttributeExistingObject(cliente, 'idCliente'),
    }),
    categoria: new FieldModel({
      name: 'categoria', value: getAttributeExistingObject(cliente, 'categoria'),
    }),
    cidade: new FieldModel({
      name: 'cidade',
      value: getAttributeExistingObject(cliente, 'cidade'),
      maxLength: 30,
    }),
    documento: new FieldModel({
      name: 'documento', value: getAttributeExistingObject(cliente, 'documento'),
    }),
    idEstado: new FieldModel({
      name: 'idEstado', value: getAttributeExistingObject(cliente, 'idEstado'),
    }),
    nome: new FieldModel({
      name: 'nome',
      value: getAttributeExistingObject(cliente, 'nome'),
      maxLength: 60,
    }),
  };
}

export function buildClientesHonorarioMinimoPorFase(honorarioMinimoFase) {
  return {
    idFase: getExistingFieldModel(honorarioMinimoFase, 'idFase'),
    porcentagemHonorarioMinimo: getExistingFieldModel(honorarioMinimoFase, 'porcentagemHonorarioMinimo'),
  };
}

export function buildClientesHonorarioMinimoPorFases(honorarioMinimoFase) {
  return honorarioMinimoFase.map(buildClientesHonorarioMinimoPorFase);
}

export function builClienteHonorario(honorario) {
  return {
    key: new FieldModel({ name: 'key', value: uuidv1() }),
    clientesHonorarioMinimoPorFase: buildClientesHonorarioMinimoPorFases(getAttributeExistingObject(honorario, 'clientesHonorarioMinimoPorFase', [])),
    idClienteHonorario: getExistingFieldModel(honorario, 'idClienteHonorario'),
    valorFaixaInicial: getExistingFieldModel(honorario, 'valorFaixaInicial'),
    valorFaixaFinal: getExistingFieldModel(honorario, 'valorFaixaFinal'),
    porcentagemHonorario: getExistingFieldModel(honorario, 'porcentagemHonorario'),
  };
}

export function buildClienteTipoCalculoHonorario(tipoCalculoHonorario) {
  if (!tipoCalculoHonorario) {
    return tipoCalculoHonorario;
  }

  const honorarios = getAttributeExistingObject(tipoCalculoHonorario, 'honorarios', []).map(builClienteHonorario);

  return {
    idTipoCalculoHonorario: getExistingFieldModel(tipoCalculoHonorario, 'idTipoCalculoHonorario'),
    descricaoTipoCalculoHonorario: getExistingFieldModel(tipoCalculoHonorario, 'descricaoTipoCalculoHonorario'),
    type: getExistingFieldModel(tipoCalculoHonorario, 'type'),
    honorarios,
  };
}

export function buildDefaultClienteComissaoMinimoPorFase(fases) {
  return fases.map(fase => ({ idFase: fase.id, porcentagemHonorarioMinimo: 0.00 }));
}

export function buildDefaultClienteHonorario(initial, final, fases) {
  return {
    valorFaixaInicial: initial,
    valorFaixaFinal: final,
    porcentagemHonorario: 0,
    clientesHonorarioMinimoPorFase: buildDefaultClienteComissaoMinimoPorFase(fases), //
  };
}

export function buildDefaultClienteTipoCalculoHonorario(id, descricao, fases) {
  const maxValue = tipoCalculoEnum[id].max;
  const honorarios = [buildDefaultClienteHonorario(0, maxValue, fases)];
  const tipoCalculoHonorario = {
    idTipoCalculoHonorario: id,
    descricaoTipoCalculoHonorario: descricao,
    type: tipoCalculoEnum[id].type,
    honorarios,
  };

  return buildClienteTipoCalculoHonorario(tipoCalculoHonorario);
}

export function buildClienteComissao(comissao) {
  return {
    key: new FieldModel({ name: 'key', value: uuidv1() }),
    idClienteComissao: getExistingFieldModel(comissao, 'idClienteComissao'),
    valorFaixaInicial: getExistingFieldModel(comissao, 'valorFaixaInicial'),
    valorFaixaFinal: getExistingFieldModel(comissao, 'valorFaixaFinal'),
    porcentagemComissao: getExistingFieldModel(comissao, 'porcentagemComissao'),
    porcentagemPagtoDiretoComissao: getExistingFieldModel(comissao, 'porcentagemPagtoDiretoComissao'),
    idTipoRegraComissaoComercial: getExistingFieldModel(comissao, 'idTipoRegraComissaoComercial', 0),
  };
}

export function buildClienteTipoCalculoComissao(tipoCalculoComissao) {
  if (!tipoCalculoComissao) {
    return tipoCalculoComissao;
  }

  const comissoes = getAttributeExistingObject(tipoCalculoComissao, 'comissoes', []).map(buildClienteComissao);

  return {
    idTipoCalculoComissao: getExistingFieldModel(tipoCalculoComissao, 'idTipoCalculoComissao', 2),
    descricaoTipoCalculoComissao: getExistingFieldModel(tipoCalculoComissao, 'descricaoTipoCalculoComissao'),
    type: getExistingFieldModel(tipoCalculoComissao, 'type'),
    comissoes,
  };
}

export function buildDefaultClienteRepasse(initial, final) {
  return {
    valorFaixaInicial: initial,
    valorFaixaFinal: final,
    porcentagemJurosRepasse: 0,
  };
}

export function buildDefaultClienteComissaoChange(initial, final) {
  return {
    valorFaixaInicial: initial,
    valorFaixaFinal: final,
    porcentagemComissao: 0,
    porcentagemPagtoDiretoComissao: 0,
    idTipoRegraComissaoComercial: 0,
  };
}

export function buildDefaultClienteComissao(initial, final, faixaComissao) {
  return faixaComissao.map(obj => ({
    valorFaixaInicial: obj?.valorInicial || initial,
    valorFaixaFinal: obj?.valorFinal || final,
    porcentagemComissao: obj?.porcentagemComissao || 0,
    porcentagemPagtoDiretoComissao: obj?.porcentagemPagtoDiretoComissao || 0,
    idTipoRegraComissaoComercial: obj?.idTipoRegraComissaoComercial || 0,
  }));
}

export function buildDefaultClienteJuros(initial, final) {
  return {
    quantidadeDiasInicial: initial,
    quantidadeDiasFinal: final,
    porcentagemJuros: 0,
    porcentagemJurosRepasse: 0,
  };
}

export function buildDefaultClienteTipoCalculoComissao(id, descricao) {
  const maxValue = tipoCalculoEnum[id].max;
  const comissoes = [buildDefaultClienteComissaoChange(0, maxValue)];
  const tipoCalculoComissao = {
    idTipoCalculoComissao: id,
    descricaoTipoCalculoComissao: descricao,
    type: tipoCalculoEnum[id].type,
    comissoes,
  };

  return buildClienteTipoCalculoComissao(tipoCalculoComissao);
}


export function buildDefaultClienteTipoCalculoComissaoParametro(id, descricao, faixaComissao) {
  const maxValue = tipoCalculoEnum[id].max;
  const comissoes = buildDefaultClienteComissao(0, maxValue, faixaComissao);

  const tipoCalculoComissao = {
    idTipoCalculoComissao: id,
    descricaoTipoCalculoComissao: descricao,
    type: tipoCalculoEnum[id].type,
    comissoes,
  };

  return buildClienteTipoCalculoComissao(tipoCalculoComissao);
}
export function buildClienteRepasse(repasse) {
  return {
    key: new FieldModel({ name: 'key', value: uuidv1() }),
    idClienteRepasse: getExistingFieldModel(repasse, 'idClienteRepasse'),
    valorFaixaInicial: getExistingFieldModel(repasse, 'valorFaixaInicial'),
    valorFaixaFinal: getExistingFieldModel(repasse, 'valorFaixaFinal'),
    porcentagemJurosRepasse: getExistingFieldModel(repasse, 'porcentagemJurosRepasse'),
  };
}

export function buildClienteJuros(repasse) {
  return {
    key: new FieldModel({ name: 'key', value: uuidv1() }),
    idClienteRepasse: getExistingFieldModel(repasse, 'idClienteJuros'),
    quantidadeDiasInicial: getExistingFieldModel(repasse, 'quantidadeDiasInicial'),
    quantidadeDiasFinal: getExistingFieldModel(repasse, 'quantidadeDiasFinal'),
    porcentagemJuros: getExistingFieldModel(repasse, 'porcentagemJuros'),
    porcentagemJurosRepasse: getExistingFieldModel(repasse, 'porcentagemJurosRepasse'),
  };
}

export function buildClienteTipoCalculoRepasse(tipoCalculoRepasse) {
  if (!tipoCalculoRepasse) {
    return tipoCalculoRepasse;
  }

  const repasses = getAttributeExistingObject(tipoCalculoRepasse, 'repasses', []).map(buildClienteRepasse);

  return {
    idTipoCalculoRepasse: getExistingFieldModel(tipoCalculoRepasse, 'idTipoCalculoRepasse'),
    descricaoTipoCalculoRepasse: getExistingFieldModel(tipoCalculoRepasse, 'descricaoTipoCalculoRepasse'),
    type: getExistingFieldModel(tipoCalculoRepasse, 'type'),
    repasses,
  };
}

export function buildJurosDosDevedores(juros) {
  if (!juros) {
    return juros;
  }
  const jurosDosDevedores = juros.map(buildClienteJuros);

  return {
    jurosDosDevedores,
  };
}


export function buildDefaultClienteTipoCalculoRepasse(id, descricao) {
  const maxValue = tipoCalculoEnum[id].max;
  const repasses = [buildDefaultClienteRepasse(0, maxValue)];
  const tipoCalculoRepasse = {
    idTipoCalculoRepasse: id,
    descricaoTipoCalculoRepasse: descricao,
    type: tipoCalculoEnum[id].type,
    repasses,
  };

  return buildClienteTipoCalculoRepasse(tipoCalculoRepasse);
}

export function buildDefaultClienteTipoCalculoJurosDevedores() {
  const maxValue = tipoCalculoEnum[1].max;
  const jurosDosDevedores = [buildDefaultClienteJuros(0, maxValue)];
  const tipoCalculoJurosDevedores = {
    jurosDosDevedores,
  };

  return buildJurosDosDevedores(tipoCalculoJurosDevedores.jurosDosDevedores);
}
