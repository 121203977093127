
import FieldModel, { MONEY, POSITIVE_INTEGER } from '../../../models/FieldModel';
import { getAttributeExistingObject } from '../../../helpers/utils/utils';
import { buildEndereco } from '../../../helpers/factory/endereco.factory';
import { buildTelefones } from '../../../helpers/factory/telefone.factory';
import { buildDialog, buildSnackbar } from '../../../helpers/factory/global.factory';
import { buildFaixasDeJurosEhRepasse } from '../../../helpers/factory/faixasDeJuros.factory';
import { buildParametroDescontosPix } from '../../../helpers/factory/descontosPix.factory';
import {
  buildTipoCalculoComissoes,
  buildTipoCalculoHonorarios,
} from '../../../helpers/factory/parametro.factory';
import {
  BUILD_PARAMETROS,
  CHANGE_EDIT_MODE,
  CHANGE_TAB_PAGE_VIEW,
  ON_CHANGE_ACTIONS,
  ON_CHANGE_ENDERECO,
  ON_OPEN_SNACKBAR,
  SET_ALERT_DIALOG,
  SET_DESCONTO_PIX_ATTRIBUTE_FOCUS,
  SET_DESCONTO_PIX_ATTRIBUTE_VALUE,
  SET_DESCONTOS_BOLETO,
  SET_DESCONTOS_BOLETO_ATTRIBUTE_FOCUS,
  SET_DESCONTOS_BOLETO_ATTRIBUTE_VALUE,
  SET_ENDERECO,
  SET_ENDERECO_ATTRIBUTE_FOCUS,
  SET_ENDERECO_ATTRIBUTE_VALUE,
  SET_IMAGE,
  SET_JUROS,
  SET_JUROS_ATTRIBUTE_FOCUS,
  SET_JUROS_ATTRIBUTE_VALUE,
  SET_LOADING,
  SET_REQUEST_FIELDS,
  SET_REQUEST_FIELDS_FOCUS,
  SET_REQUEST_FIELDS_VALUE,
  SET_TAXA_CARTAO,
  SET_TAXA_CARTAO_ATTRIBUTE_VALUE,
  SET_TAXA_CARTAO_FOCUS_VALUE,
  SET_TELEFONE_ATTRIBUTE_FOCUS,
  SET_TELEFONE_ATTRIBUTE_VALUE,
  SET_TELEFONES,
  UPDATE_REQUEST_FIELDS,
  SET_CONTA_BANCARIA_ATTRIBUTE_FOCUS,
  SET_CONTA_BANCARIA_ATTRIBUTE_VALUE,
  SET_ADICIONAR_CAMPOS,
  SET_ADICIONAR_CAMPOS_ATTRIBUTE_VALUE,
  SET_ADICIONAR_CAMPOS_ATTRIBUTE_FOCUS,
  ADD_CONTA_DEPOSITO_CLIENTE_VALUE,
  SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_VALUE,
  SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_FOCUS,
  SET_OBJECTS,
  SET_BOLETO_DEVEDOR_ATTRIBUTE_VALUE,
  SET_BOLETO_DEVEDOR_ATTRIBUTE_FOCUS,
  SET_CONTA_DEPOSITO_ATTRIBUTE_FOCUS,
  SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_VALUE,
  SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_FOCUS,
  SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_VALUE,
  SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_FOCUS,
  ADD_CONTA_PAGAMENTOS_GERAIS_VALUE,
  SET_CONTA_PAGAMENTOS_GERAIS,
  SET_FILE_CONFIG_PAGTO,
  LIMPAR_ARQUIVO_QUITACAO_DEVEDOR_ACTION,
  ALTERAR_VALOR_HONORARIOS_MINIMO_FAIXA_FASE_ACTION,
} from './crudParametros.constants';
import { buildParametroDescontoBoleto } from '../../../helpers/factory/descontoBoleto.factory';
import { buildParametroTaxasCartao } from '../../../helpers/factory/taxaCartao.factory';
import { buildcontaBancariaSispagItau } from '../../../helpers/factory/contaBancariaSispagItau.factory';
import { buildBoletoCliente } from '../../../helpers/factory/boletoCliente.factory';
import { buildBoletoDevedor } from '../../../helpers/factory/boletoDevedor.factory';


export function buildContas(contasBancarias) {
  return {
    agencia: new FieldModel({
      name: 'agencia',
      value: getAttributeExistingObject(contasBancarias, 'agencia'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    chavePix: new FieldModel({
      name: 'chavePix',
      value: getAttributeExistingObject(contasBancarias, 'chavePix'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    cnpjCpf: new FieldModel({
      name: 'cnpjCpf',
      value: getAttributeExistingObject(contasBancarias, 'cnpjCpf'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    dac: new FieldModel({
      name: 'dac',
      value: getAttributeExistingObject(contasBancarias, 'dac'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    idBanco: new FieldModel({
      name: 'idBanco',
      value: getAttributeExistingObject(contasBancarias, 'idBanco'),
    }),
    idContaBancaria: new FieldModel({
      name: 'idContaBancaria',
      value: getAttributeExistingObject(contasBancarias, 'idContaBancaria'),
    }),
    idTipoChavePix: new FieldModel({
      name: 'idTipoChavePix',
      value: getAttributeExistingObject(contasBancarias, 'idTipoChavePix'),
    }),
    idTipoContaBancaria: new FieldModel({
      name: 'idTipoContaBancaria',
      value: getAttributeExistingObject(contasBancarias, 'idTipoContaBancaria'),
    }),
    numero: new FieldModel({
      name: 'numero',
      value: getAttributeExistingObject(contasBancarias, 'numero'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    titular: new FieldModel({
      name: 'titular',
      value: getAttributeExistingObject(contasBancarias, 'titular'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
  };
}

export function buildContasBancariasDepositoClientes(contaDepositoCliente) {
  const contDepositoClienteLista = [];
  if (contaDepositoCliente.length === 0) {
    contDepositoClienteLista.push(buildContas());
    return contDepositoClienteLista;
  }
  return (contaDepositoCliente) ? contaDepositoCliente.map(buildContas) : [];
}

export function buildContasBancariasDepositosDevedores(contaDepositoDevedores) {
  const contaDepositoDevedorLista = [];
  if (contaDepositoDevedores.length === 0) {
    contaDepositoDevedorLista.push(buildContas());
    return contaDepositoDevedorLista;
  }
  return (contaDepositoDevedores) ? contaDepositoDevedores.map(buildContas) : [];
}

export function buildContasBancariasPagametosGerais(contaPagamentosGerais) {
  const contaPagamentosGeraisLista = [];
  if (contaPagamentosGerais.length === 0) {
    contaPagamentosGeraisLista.push(buildContas());
    return contaPagamentosGerais;
  }
  return (contaPagamentosGerais) ? contaPagamentosGerais.map(buildContas) : [];
}

function buildRequestFields(fields) {
  return {
    prazoLimiteTitulo: new FieldModel({
      name: 'prazoLimiteTitulo',
      value: getAttributeExistingObject(fields, 'prazoLimiteTitulo'),
      type: POSITIVE_INTEGER,
    }),
    valorMinimoProcesso: new FieldModel({
      name: 'valorMinimoProcesso',
      value: getAttributeExistingObject(fields, 'valorMinimoProcesso', 0),
      type: MONEY,
    }),
    valorLimiteFaseEletronica: new FieldModel({
      name: 'valorLimiteFaseEletronica',
      value: getAttributeExistingObject(fields, 'valorLimiteFaseEletronica', 0),
      type: MONEY,
    }),
    prazoMaximoPagtoSemAcrescimo: new FieldModel({
      name: 'prazoMaximoPagtoSemAcrescimo',
      value: getAttributeExistingObject(fields, 'prazoMaximoPagtoSemAcrescimo'),
      type: POSITIVE_INTEGER,
    }),
    idParametro: new FieldModel({
      value: 1,
    }),
    razaoSocial: new FieldModel({
      name: 'razaoSocial',
      value: getAttributeExistingObject(fields, 'razaoSocial'),
      maxLength: 100,
    }),
    cnpj: new FieldModel({
      name: 'cnpj',
      value: getAttributeExistingObject(fields, 'cnpj'),
      maxLength: 15,
    }),
    inscricaoEstadual: new FieldModel({
      name: 'inscricaoEstadual',
      value: getAttributeExistingObject(fields, 'inscricaoEstadual'),
      maxLength: 15,
    }),
    inscricaoMunicipal: new FieldModel({
      name: 'inscricaoMunicipal',
      value: getAttributeExistingObject(fields, 'inscricaoMunicipal'),
      maxLength: 15,
    }),
    email: new FieldModel({
      name: 'email',
      value: getAttributeExistingObject(fields, 'email'),
      maxLength: 50,
    }),
    tempoExpiracaoSessaoUsuario: new FieldModel({
      name: 'tempoExpiracaoSessaoUsuario',
      value: getAttributeExistingObject(fields, 'tempoExpiracaoSessaoUsuario'),
      maxLength: 3,
      type: POSITIVE_INTEGER,
    }),
    tempoExpiracaoSessaoCliente: new FieldModel({
      name: 'tempoExpiracaoSessaoCliente',
      value: getAttributeExistingObject(fields, 'tempoExpiracaoSessaoCliente'),
      maxLength: 3,
      type: POSITIVE_INTEGER,
    }),
    tempoMaximoBorderoExcluido: new FieldModel({
      name: 'tempoMaximoBorderoExcluido',
      value: getAttributeExistingObject(fields, 'tempoMaximoBorderoExcluido'),
      maxLength: 9,
      type: POSITIVE_INTEGER,
    }),
    prazoRetencaoLogBordero: new FieldModel({
      name: 'prazoRetencaoLogBordero',
      value: getAttributeExistingObject(fields, 'prazoRetencaoLogBordero'),
      type: POSITIVE_INTEGER,
    }),
    prazoRecalculo: new FieldModel({
      name: 'prazoRecalculo',
      value: getAttributeExistingObject(fields, 'prazoRecalculo'),
      type: POSITIVE_INTEGER,
    }),
    valorMaximoAcordoSeguro: new FieldModel({
      name: 'valorMaximoAcordoSeguro',
      value: getAttributeExistingObject(fields, 'valorMaximoAcordoSeguro'),
      type: MONEY,
    }),
    tipoComissaoSelecionada: new FieldModel({
      name: 'tipoComissaoSelecionada',
      value: getAttributeExistingObject(fields, 'tipoComissaoSelecionada', 2),
    }),
    tipoHonorarioSelecionado: new FieldModel({
      name: 'tipoHonorarioSelecionado',
      value: getAttributeExistingObject(fields, 'tipoHonorarioSelecionado', 2),
    }),
    idTipoRegraCalculoRepasse: new FieldModel({
      name: 'idTipoRegraCalculoRepasse',
      value: getAttributeExistingObject(fields, 'idTipoRegraCalculoRepasse', 0),
    }),
    porcentagemComissaoContato: new FieldModel({
      name: 'porcentagemComissaoContato',
      value: getAttributeExistingObject(fields, 'porcentagemComissaoContato', []),
    }),
    idTipoExpiracaoPix: new FieldModel({
      name: 'idTipoExpiracaoPix',
      value: getAttributeExistingObject(fields, 'idTipoExpiracaoPix'),
    }),
    duracaoExpiracaoPix: new FieldModel({
      name: 'duracaoExpiracaoPix',
      value: getAttributeExistingObject(fields, 'duracaoExpiracaoPix'),
    }),
    prazoExpiracaoCartaoAcordoSeguro: new FieldModel({
      name: 'prazoExpiracaoCartaoAcordoSeguro',
      value: getAttributeExistingObject(fields, 'prazoExpiracaoCartaoAcordoSeguro'),
      type: POSITIVE_INTEGER,
    }),
    valorMaximoPagtoCartao: new FieldModel({
      name: 'valorMaximoPagtoCartao',
      value: getAttributeExistingObject(fields, 'valorMaximoPagtoCartao'),
      maxLength: 9,
      type: MONEY,
    }),
    emailAtendimentoAcordoSeguro: new FieldModel({
      name: 'emailAtendimentoAcordoSeguro',
      value: getAttributeExistingObject(fields, 'emailAtendimentoAcordoSeguro'),
    }),
    telefoneContatoAcordoSeguro1: new FieldModel({
      name: 'telefoneContatoAcordoSeguro1',
      value: getAttributeExistingObject(fields, 'telefoneContatoAcordoSeguro1'),
    }),
    telefoneContatoAcordoSeguro2: new FieldModel({
      name: 'telefoneContatoAcordoSeguro2',
      value: getAttributeExistingObject(fields, 'telefoneContatoAcordoSeguro2'),
    }),
    porcentagemHonorarioExclusivoAS: new FieldModel({
      name: 'porcentagemHonorarioExclusivoAS',
      value: getAttributeExistingObject(fields, 'porcentagemHonorarioExclusivoAS'),
    }),
    prazoExpiracaoAcordoSeguro: new FieldModel({
      name: 'prazoExpiracaoAcordoSeguro',
      value: getAttributeExistingObject(fields, 'prazoExpiracaoAcordoSeguro'),
      type: POSITIVE_INTEGER,
    }),
    prazoExpiracaoExclusivoAS: new FieldModel({
      name: 'prazoExpiracaoExclusivoAS',
      value: getAttributeExistingObject(fields, 'prazoExpiracaoExclusivoAS'),
      type: POSITIVE_INTEGER,
    }),
    idFase: new FieldModel({
      name: 'idFase',
      value: getAttributeExistingObject(fields, 'idFase', 2),
    }),
    idFasePasta: new FieldModel({
      name: 'idFasePasta',
      value: getAttributeExistingObject(fields, 'idFasePasta'),
    }),
    qtdeDiasTitulosVencidos: new FieldModel({
      name: 'qtdeDiasTitulosVencidos',
      value: getAttributeExistingObject(fields, 'qtdeDiasTitulosVencidos', 720),
      type: POSITIVE_INTEGER,
    }),
    valorMinimoPrestacaoContas: new FieldModel({
      name: 'valorMinimoPrestacaoContas',
      value: getAttributeExistingObject(fields, 'valorMinimoPrestacaoContas'),
    }),
    prazoToleranciaBloqueioPrestacaoConta: new FieldModel({
      name: 'prazoToleranciaBloqueioPrestacaoConta',
      value: getAttributeExistingObject(fields, 'prazoToleranciaBloqueioPrestacaoConta'),
      type: POSITIVE_INTEGER,
    }),
    arquivoDevedorQuitacao: new FieldModel({
      name: 'arquivoDevedorQuitacao',
      value: getAttributeExistingObject(fields, 'arquivoDevedorQuitacao'),
    }),
    endereco: buildEndereco(getAttributeExistingObject(fields, 'endereco')),
    telefones: buildTelefones(getAttributeExistingObject(fields, 'telefones')),
    tiposCalculoHonorarios: buildTipoCalculoHonorarios(getAttributeExistingObject(fields, 'tiposCalculoHonorarios', [])),
    tiposCalculoComissoes: buildTipoCalculoComissoes(getAttributeExistingObject(fields, 'tiposCalculoComissoes', [])),
    jurosEhRepasse: buildFaixasDeJurosEhRepasse(getAttributeExistingObject(fields, 'jurosEhRepasse')),
    descontoPix: buildParametroDescontosPix(getAttributeExistingObject(fields, 'descontoPix', [])),
    descontoBoletos: buildParametroDescontoBoleto(getAttributeExistingObject(fields, 'descontoBoletos', [])),
    taxaCartao: buildParametroTaxasCartao(getAttributeExistingObject(fields, 'taxaCartao', [])),
    contaBancariaBoletoCliente: buildBoletoCliente(getAttributeExistingObject(fields, 'contaBancariaBoletoCliente')),
    contaBancariaBoletoDevedor: buildBoletoDevedor(getAttributeExistingObject(fields, 'contaBancariaBoletoDevedor')),
    contaBancariaSispag: buildcontaBancariaSispagItau(getAttributeExistingObject(fields, 'contaBancariaSispag')),
    contasBancariasDepositoClientes: buildContasBancariasDepositoClientes(getAttributeExistingObject(fields, 'contasBancariasDepositoClientes', [])),
    contasBancariasPagamentosGerais: buildContasBancariasPagametosGerais(getAttributeExistingObject(fields, 'contasBancariasPagamentosGerais', [])),
  };
}

function buildInitialStates(fields) {
  return {
    image: {
      file: null,
      imagePreviewUrl: null,
      error: false,
      errorMessage: '',
      errorImage: false,
    },
    quitacaoDevedorFile: null,
    requestFields: buildRequestFields(fields),
    pageView: {
      tabActive: 0,
      isEditMode: false,
    },
    actions: {
      pastas: [],
      alertDialog: buildDialog(),
      openSnackbar: buildSnackbar(),
      loading: false,
    },
  };
}

export const setImageAction = image => ({
  type: SET_IMAGE,
  image,
});

export const setFileConfigPagto = file => ({
  type: SET_FILE_CONFIG_PAGTO,
  file,
});

export const setRequestFieldsAction = (name, requestField) => ({
  type: SET_REQUEST_FIELDS,
  name,
  requestField,
});

export const setRequestFieldsValueAction = (name, value) => ({
  type: SET_REQUEST_FIELDS_VALUE,
  name,
  value,
});

export const setRequestFieldsFocusAction = name => ({
  type: SET_REQUEST_FIELDS_FOCUS,
  name,
});

export const changeEditModeAction = isEditMode => ({
  type: CHANGE_EDIT_MODE,
  isEditMode,
});

export const changeTabViewAction = activeTabView => ({
  type: CHANGE_TAB_PAGE_VIEW,
  activeTabView,
});

export const updateRequestFieldsAction = updatedRequestFields => ({
  type: UPDATE_REQUEST_FIELDS,
  updatedRequestFields,
});

export const setTelefoneAttributeFocusAction = (name, index) => ({
  type: SET_TELEFONE_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setContaPagamentosGeraisAttributeFocusAction = (name, index) => ({
  type: SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_FOCUS,
  name,
  index,
});


export const setTelefoneAttributeValueAction = (name, value, index) => ({
  type: SET_TELEFONE_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setContaPagamentosGeraisAttributeValueAction = (name, value, index) => ({
  type: SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setTelefonesAction = telefones => ({
  type: SET_TELEFONES,
  telefones,
});

export const setContaPagamentosGeraisAction = contasBancariasPagamentosGerais => ({
  type: SET_CONTA_PAGAMENTOS_GERAIS,
  contasBancariasPagamentosGerais,
});

export const setFaixasDeJurosAttributeFocusAction = (name, index) => ({
  type: SET_JUROS_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setFaixasDeJurosAttributeValueAction = (name, value, index) => ({
  type: SET_JUROS_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setFaixasDescontoBoletoAttributeValueAction = (name, value, index) => ({
  type: SET_DESCONTOS_BOLETO_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setContaDepositoClientesAttributeValueAction = (name, value, index) => ({
  type: SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});


export const setTaxaCartaoAttributeValueAction = (name, value, index) => ({
  type: SET_TAXA_CARTAO_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setTaxaCartaoAttributeFocusAction = (name, index) => ({
  type: SET_TAXA_CARTAO_FOCUS_VALUE,
  name,
  index,
});

export const setTaxaCartaoAction = taxaCartao => ({
  type: SET_TAXA_CARTAO,
  taxaCartao,
});

export const setFaixasDescontoBoletoAttributeFocusAction = (name, index) => ({
  type: SET_DESCONTOS_BOLETO_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setContaDepositoClientesAttributeFocusAction = (name, index) => ({
  type: SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_FOCUS,
  name,
  index,
});


export const setContaDepositoClienteAttributeFocus = name => ({
  type: SET_CONTA_DEPOSITO_ATTRIBUTE_FOCUS,
  name,
});

export const setAdicionarContaAttributeValueAction = (objectName, name, value, index) => ({
  type: SET_ADICIONAR_CAMPOS_ATTRIBUTE_VALUE,
  objectName,
  name,
  value,
  index,
});

export const setContaAttributeFocusAction = name => ({
  type: SET_ADICIONAR_CAMPOS_ATTRIBUTE_FOCUS,
  name,
});

export const setObjectsAction = (objectName, objects) => ({
  type: SET_OBJECTS,
  objectName,
  objects,
});

export const setFaixasDescontoBoletoAction = descontoBoletos => ({
  type: SET_DESCONTOS_BOLETO,
  descontoBoletos,
});

export const setFaixasDeJurosAction = jurosEhRepasse => ({
  type: SET_JUROS,
  jurosEhRepasse,
});

export const buildParametros = requestFields => ({
  type: BUILD_PARAMETROS,
  requestFields,
});

export const setEnderecoAction = endereco => ({
  type: SET_ENDERECO,
  endereco,
});

export const setEnderecoAttributeFocusAction = name => ({
  type: SET_ENDERECO_ATTRIBUTE_FOCUS,
  name,
});

export const setEnderecoAttributeValueAction = (name, value) => ({
  type: SET_ENDERECO_ATTRIBUTE_VALUE,
  name,
  value,
});

export const setLoadingAction = loading => ({
  type: SET_LOADING,
  loading,
});

export const setAlertDialogValuesAction = (open, variant, message, origin) => ({
  type: SET_ALERT_DIALOG,
  open,
  variant,
  message,
  origin,
});

export const setSnackBarAction = (open, variant, message, origin) => ({
  type: ON_OPEN_SNACKBAR,
  open,
  variant,
  message,
  origin,
});


export const setDescontosPixAttributeFocusAction = (name, index) => ({
  type: SET_DESCONTO_PIX_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setDescontosPixsAttributeValueAction = (name, value, index) => ({
  type: SET_DESCONTO_PIX_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

// export const setDescontosPixAction = descontoPix => ({
//   type: SET_DESCONTO_PIX,
//   descontoPix,
// });

export const setContaBacariaAttributeFocusAction = name => ({
  type: SET_CONTA_BANCARIA_ATTRIBUTE_FOCUS,
  name,
});

export const setBoletoDevedorAttributeValueAction = (name, value) => ({
  type: SET_BOLETO_DEVEDOR_ATTRIBUTE_VALUE,
  name,
  value,
});

export const setContaBoletoClienteAttributeFocusAction = name => ({
  type: SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_FOCUS,
  name,
});

export const setContaBoletoDevedorAttributeFocusAction = name => ({
  type: SET_BOLETO_DEVEDOR_ATTRIBUTE_FOCUS,
  name,
});

export const setContaBancariaAttributeValueAction = (name, value) => ({
  type: SET_CONTA_BANCARIA_ATTRIBUTE_VALUE,
  name,
  value,
});

export const setBoletoClienteAttributeValueAction = (name, value) => ({
  type: SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_VALUE,
  name,
  value,
});

export const onChangeActionsAction = (name, value) => ({
  type: ON_CHANGE_ACTIONS,
  name,
  value,
});

export const setAdicionarCampoContaDepositoAction = (objectName, objects) => ({
  type: SET_ADICIONAR_CAMPOS,
  objectName,
  objects,
});

export const addContaDepositoClientesAction = () => ({
  type: ADD_CONTA_DEPOSITO_CLIENTE_VALUE,
});

export const addContaPagamentosGeraisAction = () => ({
  type: ADD_CONTA_PAGAMENTOS_GERAIS_VALUE,
});

export const limparArquivoQuitacaoAction = () => ({
  type: LIMPAR_ARQUIVO_QUITACAO_DEVEDOR_ACTION,
});

export const alterarValorHonorariosMinimoFaixaFaseAction = (faixaNum,
  tipoHonorarioIndex,
  newFaixa) => ({
  type: ALTERAR_VALOR_HONORARIOS_MINIMO_FAIXA_FASE_ACTION,
  faixaNum,
  tipoHonorarioIndex,
  newFaixa,
});

const onChangeActionsHandler = (states, action) => ({
  ...states,
  actions: {
    ...states.actions,
    [action.name]: action.value,
  },
});

const onChangeEndercoHandler = (states, action) => ({
  ...states,
  requestFields: {
    ...states.requestFields,
    endereco: {
      ...states.requestFields.endereco,
      [action.name]: action.value,
    },
  },
});

function changeTelefoneAttributeValueHandler(states, actions) {
  const telefones = [...states.requestFields.telefones];
  telefones[actions.index][actions.name].onChange(actions.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      telefones,
    },
  };
}

function changeTelefoneAttributeFocusHandler(states, actions) {
  const telefones = [...states.requestFields.telefones];
  telefones[actions.index][actions.name].onFocus();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      telefones,
    },
  };
}

function changeTelefonesHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      telefones: [...actions.telefones],
    },
  };
}

function changePagamentosGeraisHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contasBancariasPagamentosGerais: [...actions.contasBancariasPagamentosGerais],

    },
  };
}

function setFaixasDeJurosAttributeValueHandler(states, action) {
  const jurosEhRepasse = [...states.requestFields.jurosEhRepasse];
  jurosEhRepasse[action.index][action.name].onChange(action.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      jurosEhRepasse,
    },
  };
}

function setFaixasDescontosBoletoAttributeValueHandler(states, action) {
  const descontoBoletos = [...states.requestFields.descontoBoletos];
  descontoBoletos[action.index][action.name].onChange(action.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      descontoBoletos,
    },
  };
}

function setContaDepositoClientesAttributeValueHandler(states, action) {
  const contasBancariasDepositoClientes = [...states.requestFields.contasBancariasDepositoClientes];
  contasBancariasDepositoClientes[action.index][action.name].onChange(action.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contasBancariasDepositoClientes,
    },
  };
}

function setContaPagamentosGeraisAttributeValueHandler(states, action) {
  const contasBancariasPagamentosGerais = [...states.requestFields.contasBancariasPagamentosGerais];
  contasBancariasPagamentosGerais[action.index][action.name].onChange(action.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contasBancariasPagamentosGerais,
    },
  };
}

function setFaixasDescontosBoletoAttributeFocusHandler(states, action) {
  const descontoBoletos = [...states.requestFields.descontoBoletos];
  descontoBoletos[action.index][action.name].onFocus();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      descontoBoletos,
    },
  };
}

function setContaDepositoClientesAttributeFocusHandler(states, action) {
  const contasBancariasDepositoClientes = [...states.requestFields.contasBancariasDepositoClientes];
  contasBancariasDepositoClientes[action.index][action.name].onFocus();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contasBancariasDepositoClientes,
    },
  };
}

function setContaPagamentosGeraisAttributeFocusHandler(states, actions) {
  const contasBancariasPagamentosGerais = [...states.requestFields.contasBancariasPagamentosGerais];
  contasBancariasPagamentosGerais[actions.index][actions.name].onFocus();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contasBancariasPagamentosGerais,
    },
  };
}


function setFaixasDeJurosAttributeFocusHandler(states, action) {
  const jurosEhRepasse = [...states.requestFields.jurosEhRepasse];
  jurosEhRepasse[action.index][action.name].onFocus();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      jurosEhRepasse,
    },
  };
}

function setFaixasDeJurosHandler(states, action) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      jurosEhRepasse: [...action.jurosEhRepasse],
    },
  };
}

function setFaixasDescontosBoletoHandler(states, action) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      descontoBoletos: [...action.descontoBoletos],
    },
  };
}

function buildParametrosHandler(states, actions) {
  return {
    ...states,
    ...buildInitialStates(actions.requestFields),
  };
}

function changeEnderecoAttributeFocusHandler(states, actions) {
  const field = states.requestFields.endereco[actions.name].copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      endereco: {
        ...states.requestFields.endereco,
        [actions.name]: field.onFocus(),
      },
    },
  };
}

function changeEnderecoHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      endereco: { ...actions.endereco },
    },
  };
}

function changeEnderecoAttributeValueHandler(states, actions) {
  const { name, value } = actions;
  const field = states.requestFields.endereco[name].getNewModel(value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      endereco: {
        ...states.requestFields.endereco,
        [name]: field,
      },
    },
  };
}

function changeLoadingHandler(states, actions) {
  return {
    ...states,
    loading: actions.loading,
  };
}

function updateRequestFieldsHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      ...actions.updatedRequestFields,
    },
  };
}

function changeEditModeHanlder(states, actions) {
  return {
    ...states,
    pageView: {
      ...states.pageView,
      isEditMode: actions.isEditMode,
    },
  };
}

function changeActivePageViewHandler(states, actions) {
  return {
    ...states,
    pageView: {
      ...states.pageView,
      tabActive: actions.activeTabView,
    },
  };
}

function changeAlertDialogValueHandler(states, actions) {
  const alertDialog = { ...states.alertDialog, open: actions.open };

  if (actions.variant || actions.message) {
    alertDialog.variant = actions.variant;
    alertDialog.message = actions.message;
    alertDialog.origin = actions.origin;
  }
  return {
    ...states,
    actions: {
      ...states.actions,
      alertDialog,
    },
  };
}

function openSnackbarHandler(states, actions) {
  const openSnackbar = { ...states.openSnackbar, open: actions.open };

  if (actions.variant || actions.message) {
    openSnackbar.variant = actions.variant;
    openSnackbar.message = actions.message;
    openSnackbar.origin = actions.origin;
  }
  return {
    ...states,
    actions: {
      ...states.actions,
      openSnackbar,
    },
  };
}

function changeRequestFieldsValueHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.requestFields[actions.name].copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: fieldModel.onChange(value),
    },
  };
}

function changeRequestFieldsFocusHandler(states, actions) {
  const { name } = actions;
  const fieldModel = states.requestFields[actions.name].copy();
  return { ...states, requestFields: { ...states.requestFields, [name]: fieldModel.onFocus() } };
}

function changeRequestFieldHandler(states, actions) {
  const { name, requestField } = actions;
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: requestField,
    },
  };
}

const setImageHandler = (states, action) => ({
  ...states,
  image: { ...action.image },
});


function setTaxaCartaoAttributeValueHandler(states, action) {
  const taxaCartao = [...states.requestFields.taxaCartao];
  taxaCartao[action.index][action.name].onChange(action.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      taxaCartao,
    },
  };
}

function setTaxaCartaoAttributeFocusHandler(states, action) {
  const taxaCartao = [...states.requestFields.taxaCartao];
  taxaCartao[action.index][action.name].onFocus();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      taxaCartao,
    },
  };
}

function setTaxaCartaoHandler(states, action) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      taxaCartao: [...action.taxaCartao],
    },
  };
}

function setDescontosPixAttributeValueHandler(states, action) {
  const descontoPix = [...states.requestFields.descontoPix];
  descontoPix[action.index][action.name].onChange(action.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      descontoPix,
    },
  };
}

function setDescontosPixAttributeFocusHandler(states, action) {
  const descontoPix = [...states.requestFields.descontoPix];
  descontoPix[action.index][action.name].onFocus();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      descontoPix,
    },
  };
}

function changeContaBancariaAttributeValueHandler(states, actions) {
  const model = states.requestFields.contaBancariaSispag[actions.name]?.copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contaBancariaSispag: {
        ...states.requestFields.contaBancariaSispag,
        [actions.name]: model?.onChange(actions.value),
      },
    },
  };
}

function changeBoletoClienteAttributeValueHandler(states, actions) {
  const model = states.requestFields.contaBancariaBoletoCliente[actions.name]?.copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contaBancariaBoletoCliente: {
        ...states.requestFields.contaBancariaBoletoCliente,
        [actions.name]: model?.onChange(actions.value),
      },
    },
  };
}

function changeBoletoDevedorAttributeValueHandler(states, actions) {
  const model = states.requestFields.contaBancariaBoletoDevedor[actions.name]?.copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contaBancariaBoletoDevedor: {
        ...states.requestFields.contaBancariaBoletoDevedor,
        [actions.name]: model?.onChange(actions.value),
      },
    },
  };
}

function changeContaBancariaAttributeFocusHandler(states, actions) {
  const field = states.requestFields.contaBancariaSispag[actions.name].copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contaBancariaSispag: {
        ...states.requestFields.contaBancariaSispag,
        [actions.name]: field.onFocus(),
      },
    },
  };
}

function changeBoletoClienteAttributeFocusHandler(states, actions) {
  const field = states.requestFields.contaBancariaBoletoCliente[actions.name].copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contaBancariaBoletoCliente: {
        ...states.requestFields.contaBancariaBoletoCliente,
        [actions.name]: field.onFocus(),
      },
    },
  };
}

function changeBoletoDevedorAttributeFocusHandler(states, actions) {
  const field = states.requestFields.contaBancariaBoletoDevedor[actions.name].copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contaBancariaBoletoDevedor: {
        ...states.requestFields.contaBancariaBoletoDevedor,
        [actions.name]: field.onFocus(),
      },
    },
  };
}

function setCampoContaDepositoHandler(states, action) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [action.objectName]: [...action.objects],
    },
  };
}

function setAdicionarContaAttributeFocusHandler(states, action) {
  const contasBancariasDepositoClientes = states.requestFields.contasBancariasDepositoClientes[action.name].copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contasBancariasDepositoClientes: {
        ...states.requestFields.contasBancariasDepositoClientes,
        [action.name]: contasBancariasDepositoClientes.onFocus(),
      },
    },
  };
}

function setAdicionarCamposAttributeValue(states, action) {
  const serDepositoCliente = [...states.requestFields[action.objectName]];
  serDepositoCliente[action.index][action.name].onChange(action.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [action.objectName]: serDepositoCliente,
    },
  };
}


function setObjectsHandler(states, action) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [action.objectName]: [...action.objects],
    },
  };
}

function addContaDepositoClientes(states) {
  const { contasBancariasDepositoClientes } = states.requestFields;
  const newConta = buildContas();
  contasBancariasDepositoClientes.push(newConta);

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contasBancariasDepositoClientes,
    },
  };
}

function addContaPagamentosGerais(states) {
  const { contasBancariasPagamentosGerais } = states.requestFields;
  const newConta = buildContas();
  contasBancariasPagamentosGerais.push(newConta);

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      contasBancariasPagamentosGerais,
    },
  };
}

function setFileConfigPagtoHandler(states, action) {
  const { file } = action;

  return {
    ...states,
    quitacaoDevedorFile: file,
  };
}

function limparArquivoQuitacaoDevedorHandler(states) {
  return {
    ...states,
    quitacaoDevedorFile: null,
    requestFields: {
      ...states.requestFields,
      arquivoDevedorQuitacao: new FieldModel({
        name: 'arquivoDevedorQuitacao',
        value: null,
      }),
    },
  };
}

function handlerAlterarValorHonorariosMinimoFaixaFaseHandler(states, action) {
  const {
    faixaNum,
    tipoHonorarioIndex,
    newFaixa,
  } = action;

  const { tiposCalculoHonorarios } = states.requestFields;
  tiposCalculoHonorarios[tipoHonorarioIndex].honorarios[faixaNum] = newFaixa;
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      tiposCalculoHonorarios,
    },
  };
}


export default (states = buildInitialStates(), action) => {
  switch (action.type) {
    case ON_CHANGE_ACTIONS:
      return onChangeActionsHandler(states, action);
    case ON_CHANGE_ENDERECO:
      return onChangeEndercoHandler(states, action);
    case SET_TELEFONE_ATTRIBUTE_FOCUS:
      return changeTelefoneAttributeFocusHandler(states, action);
    case SET_TELEFONE_ATTRIBUTE_VALUE:
      return changeTelefoneAttributeValueHandler(states, action);
    case SET_TELEFONES:
      return changeTelefonesHandler(states, action);
    case SET_CONTA_PAGAMENTOS_GERAIS:
      return changePagamentosGeraisHandler(states, action);
    case BUILD_PARAMETROS:
      return buildParametrosHandler(states, action);
    case SET_ENDERECO_ATTRIBUTE_FOCUS:
      return changeEnderecoAttributeFocusHandler(states, action);
    case SET_ENDERECO:
      return changeEnderecoHandler(states, action);
    case SET_ENDERECO_ATTRIBUTE_VALUE:
      return changeEnderecoAttributeValueHandler(states, action);
    case SET_LOADING:
      return changeLoadingHandler(states, action);
    case UPDATE_REQUEST_FIELDS:
      return updateRequestFieldsHandler(states, action);
    case SET_ALERT_DIALOG:
      return changeAlertDialogValueHandler(states, action);
    case ON_OPEN_SNACKBAR:
      return openSnackbarHandler(states, action);
    case SET_REQUEST_FIELDS_VALUE:
      return changeRequestFieldsValueHandler(states, action);
    case SET_REQUEST_FIELDS_FOCUS:
      return changeRequestFieldsFocusHandler(states, action);
    case CHANGE_EDIT_MODE:
      return changeEditModeHanlder(states, action);
    case CHANGE_TAB_PAGE_VIEW:
      return changeActivePageViewHandler(states, action);
    case SET_REQUEST_FIELDS:
      return changeRequestFieldHandler(states, action);
    case SET_JUROS_ATTRIBUTE_FOCUS:
      return setFaixasDeJurosAttributeFocusHandler(states, action);
    case SET_JUROS_ATTRIBUTE_VALUE:
      return setFaixasDeJurosAttributeValueHandler(states, action);
    case SET_JUROS:
      return setFaixasDeJurosHandler(states, action);
    case SET_IMAGE:
      return setImageHandler(states, action);
    case SET_FILE_CONFIG_PAGTO:
      return setFileConfigPagtoHandler(states, action);
    case SET_DESCONTOS_BOLETO:
      return setFaixasDescontosBoletoHandler(states, action);
    case SET_DESCONTOS_BOLETO_ATTRIBUTE_VALUE:
      return setFaixasDescontosBoletoAttributeValueHandler(states, action);
    case SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_VALUE:
      return setContaDepositoClientesAttributeValueHandler(states, action);
    case SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_VALUE:
      return setContaPagamentosGeraisAttributeValueHandler(states, action);
    case SET_DESCONTOS_BOLETO_ATTRIBUTE_FOCUS:
      return setFaixasDescontosBoletoAttributeFocusHandler(states, action);
    case SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_FOCUS:
      return setContaDepositoClientesAttributeFocusHandler(states, action);
    case SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_FOCUS:
      return setContaPagamentosGeraisAttributeFocusHandler(states, action);
    case ALTERAR_VALOR_HONORARIOS_MINIMO_FAIXA_FASE_ACTION:
      return handlerAlterarValorHonorariosMinimoFaixaFaseHandler(states, action);

    default:
      return states;
    case SET_DESCONTO_PIX_ATTRIBUTE_FOCUS:
      return setDescontosPixAttributeFocusHandler(states, action);
    case SET_DESCONTO_PIX_ATTRIBUTE_VALUE:
      return setDescontosPixAttributeValueHandler(states, action);
    case SET_TAXA_CARTAO:
      return setTaxaCartaoHandler(states, action);
    case SET_TAXA_CARTAO_ATTRIBUTE_VALUE:
      return setTaxaCartaoAttributeValueHandler(states, action);
    case SET_TAXA_CARTAO_FOCUS_VALUE:
      return setTaxaCartaoAttributeFocusHandler(states, action);
    // case SET_DESCONTO_PIX:
    //   return setDescontosPixHandler(states, action);
    case SET_CONTA_BANCARIA_ATTRIBUTE_VALUE:
      return changeContaBancariaAttributeValueHandler(states, action);
    case SET_CONTA_BANCARIA_ATTRIBUTE_FOCUS:
      return changeContaBancariaAttributeFocusHandler(states, action);
    case SET_ADICIONAR_CAMPOS:
      return setCampoContaDepositoHandler(states, action);
    case SET_ADICIONAR_CAMPOS_ATTRIBUTE_FOCUS:
      return setAdicionarContaAttributeFocusHandler(states, action);
    case SET_ADICIONAR_CAMPOS_ATTRIBUTE_VALUE:
      return setAdicionarCamposAttributeValue(states, action);
    case ADD_CONTA_DEPOSITO_CLIENTE_VALUE:
      return addContaDepositoClientes(states);
    case ADD_CONTA_PAGAMENTOS_GERAIS_VALUE:
      return addContaPagamentosGerais(states);
    case SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_VALUE:
      return changeBoletoClienteAttributeValueHandler(states, action);
    case SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_FOCUS:
      return changeBoletoClienteAttributeFocusHandler(states, action);
    case SET_OBJECTS:
      return setObjectsHandler(states, action);
    case SET_BOLETO_DEVEDOR_ATTRIBUTE_VALUE:
      return changeBoletoDevedorAttributeValueHandler(states, action);
    case SET_BOLETO_DEVEDOR_ATTRIBUTE_FOCUS:
      return changeBoletoDevedorAttributeFocusHandler(states, action);
    case LIMPAR_ARQUIVO_QUITACAO_DEVEDOR_ACTION:
      return limparArquivoQuitacaoDevedorHandler(states);
  }
};
