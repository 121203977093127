import React, {
  useMemo, useState,
} from 'react';
import { Typography } from '@material-ui/core';
import { FaClipboardCheck, FaPlayCircle } from 'react-icons/fa';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import useStyle, {
  AcoesSelecao, AcoesEmMassa, Button, Acao,
} from './AcoesPesquisaProcesso,.styles';
import SnackbarUI from '../../../UI/Snackbar/Snackbar';
import { SNACKBAR_DURATION } from '../../../../helpers/constants/global.constants';

import { extractResponseErrorMessage } from '../../../../helpers/utils/form.utils';
import { buscarFasesAndInfoAtuaisProcessos, validarQuantidadeProcessosSelecionadoService } from '../../../../services/core/processos/processosService';
import DialogNew from '../../../../layouts/FormDialog/DialogNew';
import DialogEnviarProcessosOutraFase from './DialogEnviarProcessosOutraFase/DialogEnviarProcessosOutraFase';
import DialogEnviarEmailDevedor from './DialogEnviarEmailDevedor/DialogEnviarEmailDevedor';


function AcoesPesquisaProcessoComponent({
  idsProcessos, totalSelecionados, simpleSearchFormRef, advancedSearchRef, setLoading,
}) {
  const history = useHistory();
  const styles = useStyle();
  const [showAcoesMassa, setShowAcoesMassa] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState({ open: false, message: '' });

  // Dialogs
  const [openEnviarProcessosOutraFase, setOpenEnviarProcessosOutraFase] = useState(false);
  const [openEnviarEmailDevedores, setOpenEnviarEmailDevedores] = useState(false);


  const [fasesAndInfoProcessosSelecionados, setFasesAndInfoProcessosSelecionados] = useState([]);

  const fasesPermitidasUsuario = useSelector(state => state.authentication.userInformation.fases);

  const permissoesUsuario = useSelector(state => state.authentication.userInformation.permissoes);

  const processosSelecionados = useMemo(() => Object.keys(idsProcessos).filter(key => idsProcessos[key] === true), [idsProcessos]);

  const elementsEqualFase = useMemo(() => (new Set(fasesAndInfoProcessosSelecionados.map(fase => fase.faseAtual))).size === 1, [fasesAndInfoProcessosSelecionados]);

  const disableButtonEnviarProcessosOutraFase = useMemo(() => !fasesAndInfoProcessosSelecionados.every(fase => fase.estagioFase !== 'U'
    && fasesPermitidasUsuario.some(faseUser => fase.faseAtual === parseInt(faseUser, 10))),
  [fasesPermitidasUsuario, fasesAndInfoProcessosSelecionados]);

  const disableButtonEnviarEmailDevedores = useMemo(() => (!permissoesUsuario.includes('ROLE_1210')
  && (!elementsEqualFase
    || !fasesAndInfoProcessosSelecionados.every(fase => fase.estagioFase !== 'U'
      && fasesPermitidasUsuario.some(faseUser => fase.faseAtual === parseInt(faseUser, 10))))),
  [fasesPermitidasUsuario, fasesAndInfoProcessosSelecionados, elementsEqualFase, permissoesUsuario]);

  const processosPermitemFaseCobrancaPresencial = useMemo(() => fasesAndInfoProcessosSelecionados.every(process => process.permitirCobrancaPresencial === 'S'), [fasesAndInfoProcessosSelecionados]);

  const processosTemAgendamento = useMemo(() => fasesAndInfoProcessosSelecionados.some(process => process.temAgendamento === 'S'), [fasesAndInfoProcessosSelecionados]);

  const processosTemRequisicao = useMemo(() => fasesAndInfoProcessosSelecionados.some(process => process.temRequisicao === 'S'), [fasesAndInfoProcessosSelecionados]);

  /* Condições para desabilitar botão Unificar Processos, botão foi removido

  const permissoesUsuario = useSelector(state => state.authentication.userInformation.permissoes);

  const disableButtonUnificarProcessos = useMemo(() => {
    const elementsEqualCliente = (new Set(fasesAndInfoProcessosSelecionados.map(process => process.idCliente))).size === 1;
    const elementsEqualDevedor = (new Set(fasesAndInfoProcessosSelecionados.map(process => process.idDevedor))).size === 1;
    const elementsEqualCobrancaJudicial = (new Set(fasesAndInfoProcessosSelecionados.map(process => process.existirCobrancaJudicial))).size === 1;
    const processosSemAcordosAssociados = fasesAndInfoProcessosSelecionados.every(process => process.acordosAssociados === 0);

    return (!permissoesUsuario.includes('ROLE_1204')
      || !processosSemAcordosAssociados
      || !elementsEqualCobrancaJudicial
      || !elementsEqualCliente
      || !elementsEqualDevedor
      || !elementsEqualFase
      || !fasesAndInfoProcessosSelecionados.every(fase => fase.estagioFase !== 'U' && fase.faseAtual !== 2
      && fasesPermitidasUsuario.some(faseUser => fase.faseAtual === parseInt(faseUser, 10))));
  },
  [fasesPermitidasUsuario, fasesAndInfoProcessosSelecionados, permissoesUsuario, elementsEqualFase]);
 */

  function onOpenAcaoMassa() {
    if (showAcoesMassa === false) {
      buscarFasesAndInfoAtuaisProcessos(processosSelecionados).then((response) => {
        setFasesAndInfoProcessosSelecionados(response.data);
      });
    }
    setShowAcoesMassa(!showAcoesMassa);
  }

  function closeSnackbar() {
    setErrorSnackbar({ ...errorSnackbar, open: false });
  }

  function onCloseAcaoMassa() {
    setShowAcoesMassa(false);
  }


  async function goToInspecaoProcesso() {
    try {
      await validarQuantidadeProcessosSelecionadoService(totalSelecionados);

      const idzProcessos = Object.keys(idsProcessos).filter((key => idsProcessos[key] === true)).map(key => Number(key));
      sessionStorage.setItem('idsProcessos', JSON.stringify(idzProcessos));
      localStorage.setItem('previousUrl', '');
      history.push('/processos/inspecao');
    } catch (error) {
      toast.error(extractResponseErrorMessage(error),
        { style: { width: '392px' } });
    }
  }

  return (
    <>
      <SnackbarUI
        open={errorSnackbar.open}
        autoHideDuration={SNACKBAR_DURATION}
        variant="error"
        message={errorSnackbar.message}
        onClose={closeSnackbar}

      />
      <DialogNew
        open={openEnviarProcessosOutraFase}
        text="Enviar Processos para outra Fase"
        className={styles.dialogEnviarProcessosParaOutraFase}
      >
        <DialogEnviarProcessosOutraFase
          processosSelecionados={processosSelecionados}
          processosMesmaFase={elementsEqualFase}
          faseProcessos={elementsEqualFase ? fasesAndInfoProcessosSelecionados[0].faseAtual : null}
          processosPermitemFaseCobrancaPresencial={processosPermitemFaseCobrancaPresencial}
          setOpenDialog={setOpenEnviarProcessosOutraFase}
          processosTemAgendamento={processosTemAgendamento}
          processosTemRequisicao={processosTemRequisicao}
          simpleSearchFormRef={simpleSearchFormRef}
          advancedSearchRef={advancedSearchRef}
        />
      </DialogNew>
      <DialogNew
        open={openEnviarEmailDevedores}
        text="Enviar E-mail para os devedores"
        className={styles.dialogEnviarEmailDevedores}
      >
        <DialogEnviarEmailDevedor
          processosSelecionados={processosSelecionados}
          setOpenEnviarEmailDevedores={setOpenEnviarEmailDevedores}
          setLoading={setLoading}
        />
      </DialogNew>
      <AcoesSelecao>
        <Typography>{`${totalSelecionados} Selecionado${totalSelecionados > 1 ? 's' : ''}`}</Typography>
        <div>
          <AcoesEmMassa disabled>
            <Button
              type="button"
              disabled={totalSelecionados < 2}
              onClick={onOpenAcaoMassa}
            >
              <FaClipboardCheck size={16} />
              <span>Ações em Massa</span>
            </Button>
            {showAcoesMassa && (
              <ul onMouseLeave={onCloseAcaoMassa}>
                { /*
                <li>
                  <Acao disabled={disableButtonUnificarProcessos}>
                    Unificar Processos
                  </Acao>
                </li>
                */ }
                <li>
                  <Acao disabled={disableButtonEnviarProcessosOutraFase} onClick={() => setOpenEnviarProcessosOutraFase(true)}>
                    Enviar Processos para outra Fase
                  </Acao>
                </li>
                <li>
                  <Acao disabled={disableButtonEnviarEmailDevedores} onClick={() => setOpenEnviarEmailDevedores(true)}>Enviar e-mail aos Devedores</Acao>
                </li>
              </ul>
            )}
          </AcoesEmMassa>
          <Button
            type="button"
            disabled={totalSelecionados === 0}
            onClick={goToInspecaoProcesso}
          >
            <FaPlayCircle size={16} />
            <span>Trabalhar com</span>
          </Button>

        </div>
      </AcoesSelecao>
    </>
  );
}

export const AcoesPesquisaProcesso = AcoesPesquisaProcessoComponent;
