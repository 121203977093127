import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TaxasDesconto from './TaxasDesconto/TaxasDesconto';
import { useCrudClienteDispatch } from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { onChangeControls } from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import Honorarios from './Honorarios';
import Grid24 from '../../../../UI/Grid24/Grid24';
import Comissoes from './Comissoes/Comissoes';
import JurosDosDevedores from './JurosDosDevedores/JurosDosDevedores';
import Campanha from './Campanha/Campanha';
import { findRegraCalculoRepassesAction, findTipoDescontoAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';


function BaseDeCalculo({ isEditMode, isInsertMode }) {
  const dispatch = useCrudClienteDispatch();
  const globalDispatch = useDispatch();

  const onChangeBaseCalculoInputFields = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeControls(name, value));
  }, [dispatch]);

  const onChangeSelector = useCallback((event) => {
    dispatch(onChangeControls('porcentagemDesconto', 0));
    onChangeBaseCalculoInputFields(event);
  }, [dispatch, onChangeBaseCalculoInputFields]);

  useEffect(() => {
    globalDispatch(findTipoDescontoAction());
    globalDispatch(findRegraCalculoRepassesAction());
  }, [globalDispatch]);

  return (
    <>
      <Grid24 container isFormContainer>
        <TaxasDesconto
          isEditMode={isEditMode}
          onChange={onChangeBaseCalculoInputFields}
          onChangeSelector={onChangeSelector}
        />
      </Grid24>

      <Grid24 container isFormContainer>
        <JurosDosDevedores disabled={!isEditMode} />
      </Grid24>

      <Grid24 container isFormContainer>
        <Honorarios disabled={!isEditMode} />
      </Grid24>

      <Grid24 container isFormContainer>
        <Comissoes disabled={!isEditMode} isInsertMode={isInsertMode} />
      </Grid24>

      <Grid24 container isFormContainer>
        <Campanha
          disabled={!isEditMode}
          dispatch={dispatch}
        />
      </Grid24>
    </>
  );
}

export default BaseDeCalculo;
