import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Telefones from '../../../../UI/TelefonesV2/Telefones';
import {
  setObjectAttributeAction,
  setObjectFocusAction,
  setObjectsAction,
} from '../../../../../store/Cliente/V2/Crud/crudCliente.store';

import { findTiposTelefoneAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';

function TelefonesCrudCliente({ isEditMode }) {
  const utilStyles = useUtilStyles();

  const dispatch = useCrudClienteDispatch();
  const globalDispatch = useDispatch();

  const telefones = useCrudClienteSelector(states => states.controls.telefones);
  const tiposTelefone = useSelector(states => states.selectors.tiposTelefone.selector);
  const onReloadAllCombos = useCrudClienteSelector(states => states.actions.reloadAllCombos);

  useEffect(() => {
    globalDispatch(findTiposTelefoneAction());
  }, [globalDispatch, onReloadAllCombos]);

  const setAttributeHandler = useCallback((name, value, index) => {
    dispatch(setObjectAttributeAction('telefones', name, value, index));
  }, [dispatch]);

  const setFocusHandler = useCallback((name, index) => {
    dispatch(setObjectFocusAction('telefones', name, index));
  }, [dispatch]);

  const setObjectsHandler = useCallback((param) => {
    dispatch(setObjectsAction('telefones', param));
  }, [dispatch]);

  return (
    <Telefones
      className={utilStyles.mt16}
      disabled={!isEditMode}
      readMode={!isEditMode}
      telefones={telefones}
      tiposTelefone={tiposTelefone}
      setAttribute={setAttributeHandler}
      setFocus={setFocusHandler}
      setTelefones={setObjectsHandler}
      requiredFields={{
        tipoTelefone: isEditMode,
        numero: isEditMode,
      }}
    />
  );
}

export default TelefonesCrudCliente;
