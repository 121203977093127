import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Box } from '@material-ui/core';
import { FaEraser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import EnviarEmailDevedor from './EnviarEmailDevedor';
import { acaoEnviarConsultaClienteController as controller } from './acaoEnviarConsultaClienteController';
import WrapperSubTitleForm from '../../../components/UI/WrapperSubTitleForm';
import useStyles from './acaoEnviarEmailDevedorStyles';
import { CustomButton } from '../InspecionarProcessoStyles';
import { buscarInfoInicialLimparEnvioEmail, submitFormEnvioEmailDevedor } from '../../../services/core/processos/processosEnviarEmailDevedorService';
import { loadingInspecaoProcessoController } from '../loadingInspecaoProcessoController';
import { findAcordosService } from '../../../services/core/processos/processosAcordosService';


function AcaoEnviarEmailDevedor({
  processo, setLoadings, updatePage,
  idFaseAtual,
  idProcesso,
  clientePermiteUnificar,
  dadosProcesso,
  fasesIds,
  podeModificarFase,
  disabledAntecedentes,
}) {
  const [formFields, setFormFields] = useState(controller.makeFormFields());
  const [errorFields, setErrorFields] = useState(controller.makeErrorFields());
  const [anexos, setAnexos] = useState([]);
  const [textoValue, setTextoValue] = useState('');

  const styles = useStyles();

  const [antecedentesSelecionados] = useState([]);

  const limparFormularioEstadoInicial = useCallback(() => {
    buscarInfoInicialLimparEnvioEmail(processo.id).then((response) => {
      const { data } = response;
      setFormFields({
        emailDe: data.emailDe || '',
        emailPara: data.emailPara || '',
        emailCopia: data.emailCopia || '',
        respostaPara: data.respostaPara || '',
        assunto: data.assunto || '',
        nomeModelo: '',
        anexos: [],
        texto: data.texto || '',
      });
      setTextoValue(data.texto);
    }).catch(() => {
      toast.error('Erro ao buscar informações de email.');
    });
  }, [processo.id]);

  const onSubmitFormEnviarEmail = useCallback((setOpenDialog) => {
    setLoadings(loadingInspecaoProcessoController.changeLoadingAction(true));
    antecedentesSelecionados.forEach((idSelecionado) => { submitFormEnvioEmailDevedor(idSelecionado, formFields, anexos); });
    submitFormEnvioEmailDevedor(processo.id, formFields, anexos).then(() => {
      limparFormularioEstadoInicial();
      updatePage();
      toast.success('E-mail enviado com sucesso!');
    }).catch((e) => {
      if (e.response.data.validations && e.response.data.validations.length > 0) {
        e.response.data.validations.forEach((validation) => {
          setErrorFields(errFields => ({
            ...errFields,
            [validation.field]: {
              error: true,
              errorMessage: validation.message,
            },
          }));
        });
      }
      toast.error('Erro no envio do e-mail. Tente novamente.');
    }).finally(() => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAction(false));
      setOpenDialog(false);
    });
    findAcordosService(idProcesso, 0, 10, [
      'nroAcordo,DESC',
    ], true);
  }, [idProcesso, antecedentesSelecionados, formFields, processo.id, anexos, setLoadings, limparFormularioEstadoInicial, updatePage]);


  useEffect(() => {
    limparFormularioEstadoInicial();
  }, [limparFormularioEstadoInicial]);

  const disableEnviar = useMemo(() => (formFields.emailDe === null || formFields.emailDe === '')
    || (formFields.emailPara === null || formFields.emailPara === '')
    || (formFields.assunto === null || formFields.assunto === '')
    || (formFields.texto === null || formFields.texto === ''), [formFields]);

  return (
    <Box>
      <Box className={styles.headerBoxEnviarEmail}>
        <WrapperSubTitleForm className={styles.titleEnvioEmail}>Envio de e-mail ao Devedor</WrapperSubTitleForm>
        <CustomButton onClick={() => { limparFormularioEstadoInicial(); setAnexos([]); }}>
          <FaEraser size={18} />
          <span>Limpar</span>
        </CustomButton>
      </Box>
      <EnviarEmailDevedor
        onSubmitForm={onSubmitFormEnviarEmail}
        formFields={formFields}
        errorFields={errorFields}
        setFormFields={setFormFields}
        setErrorFields={setErrorFields}
        setAnexos={setAnexos}
        disableEnviar={disableEnviar}
        textoValue={textoValue}
        setTextoValue={setTextoValue}
        idFaseAtual={idFaseAtual}
        idProcesso={idProcesso}
        clientePermiteUnificar={clientePermiteUnificar}
        dadosProcesso={dadosProcesso}
        fasesIds={fasesIds}
        podeModificarFase={podeModificarFase}
        antecedentesSelecionados={antecedentesSelecionados}
        setLoadings={setLoadings}
        updatePage={updatePage}
        disabledAntecedentes={disabledAntecedentes}
      />
    </Box>

  );
}


export default AcaoEnviarEmailDevedor;
