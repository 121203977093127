import React, { useCallback, useRef, useState } from 'react';
import {
  MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdLibraryBooks, MdLightbulbOutline,
} from 'react-icons/md';
import { LuMails } from 'react-icons/lu';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Typography } from '@material-ui/core';
import { Button } from '@mui/material';

import { ButtonUI, InputField } from '../../../components';
import { clearErrorField, getFieldName } from '../../../helpers';
import EditorTexto from '../../../components/UI/EditorTexto';
import { FooterContainer } from '../AcaoEnviar/acaoEnviarStyles';
import { TextoPadraoButton } from '../InspecionarProcessoStyles';
import DialogGPT from '../../../components/DialogGPT/DialogGPT';
import AutoTextoDialog from '../AutoTextoDialog/AutoTextoDialog';
import EmailsPadraoDialog from '../EmailsPadraoDialog/EmailsPadraoDialog';
import { InputFileIcon } from '../../../components/UI/InputFile/InputFileIcon';
import Grid24 from '../../../components/UI/Grid24';
import useStyles, { ButtonInverterParaCopia } from './enviarEmailDevedorStyles';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import TooltipUI from '../../../components/UI/Tooltip/Tooltip';
import { LightIndicator } from '../../../components/Title/Title.styles';
import DialogNew from '../../../layouts/FormDialog/DialogNew';
import { AntecedenteProcesso } from '../AntecendentesProcesso';

function EnviarEmailDevedor({
  onSubmitForm, formFields, errorFields, setFormFields, setErrorFields, setAnexos, disableEnviar = false, textoValue, setTextoValue, isAcaoEmMassa, idFaseAtual,
  idProcesso,
  clientePermiteUnificar,
  dadosProcesso,
  fasesIds,
  podeModificarFase,
  antecedentesSelecionados,
  setAntecedentesSelecionados,
  setLoadings,
  updatePage,
  disabledAntecedentes,
}) {
  const [desabilitarReescrever, setDesabilitarReescrever] = useState(true);
  const [showDialogTest, setShowDialogTest] = useState(false);

  const formRef = useRef();
  const editorRef = useRef();

  const styles = useStyles();

  // Controle de dialogs

  const [openEmailsPadrao, setOpenEmailPadrao] = useState(false);
  const [openAutoTextoDialog, setOpenAutoTextoDialog] = useState(false);
  const [openDialogConfirmarEnvio, setOpenDialogConfirmarEnvio] = useState(false);

  const [openDialogAntecedentes, setOpenDialogAntecedentes] = useState(false);

  const insertTextAtCursor = useCallback((textToInsert) => {
    const quill = editorRef.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      quill.insertText(selection.index, textToInsert);
    } else {
      quill.insertText(quill.getLength(), textToInsert);
    }
  }, []);


  const openMelhorarTextoDialog = () => {
    setShowDialogTest(true);
  };

  const onChangeHandler = useCallback((e) => {
    const { value } = e.target;
    const name = getFieldName(e.target);
    setFormFields(oldValue => ({ ...oldValue, [name]: value }));
  }, [setFormFields]);

  const onFocusHander = useCallback((e) => {
    setErrorFields(clearErrorField(errorFields, getFieldName(e.target)));
  }, [errorFields, setErrorFields]);

  const onChangeTexto = useCallback((event) => {
    const qtdePalavras = event.trim().split(' ').length;
    setTextoValue(event);
    setFormFields(oldValue => ({ ...oldValue, texto: event }));
    setDesabilitarReescrever(qtdePalavras < 5);
  }, [setFormFields, setTextoValue]);

  const onFocusTexto = () => {
    setErrorFields(oldValues => ({ ...oldValues, texto: { error: false } }));
  };

  const updateDescricaoAtividades = (newText) => {
    setTextoValue(newText);
    setFormFields(oldValue => ({ ...oldValue, texto: newText }));
  };

  const handleInverterParaCopia = useCallback(() => {
    setFormFields(oldValues => ({ ...oldValues, emailCopia: oldValues.emailPara, emailPara: oldValues.emailCopia }));
  }, [setFormFields]);

  return (
    <>
      <DialogNew
        open={openDialogAntecedentes}
        text="Antecedentes"
        className={styles.dialog}
      >
        <Typography component="p" className={styles.typography}>
          Selecione os antecedentes:
        </Typography>
        <AntecedenteProcesso
          idProcesso={idProcesso}
          setLoadings={setLoadings}
          clientePermiteUnificar={clientePermiteUnificar}
          dadosProcesso={dadosProcesso}
          updatePage={updatePage}
          podeModificarFase={podeModificarFase}
          idFaseProcesso={idFaseAtual}
          fasesIds={fasesIds}
          accordion={false}
          antecedentesSelecionados={antecedentesSelecionados}
        />
        <div className={styles.div__footer}>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
            <Button
              onClick={() => {
                setOpenDialogAntecedentes(false);
                if (setAntecedentesSelecionados) {
                  setAntecedentesSelecionados([]);
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => { setOpenDialogAntecedentes(false); }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </DialogNew>
      <OptionDialogNew
        open={openDialogConfirmarEnvio}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={() => {
          onSubmitForm(setOpenDialogConfirmarEnvio);
        }}
        onClickCancel={() => {
          setOpenDialogConfirmarEnvio(false);
        }}
      >
        <p>O e-mail digitado será enviado ao Devedor. Confirma o envio?</p>
      </OptionDialogNew>
      <AutoTextoDialog
        open={openAutoTextoDialog}
        closeDialogHandler={() => setOpenAutoTextoDialog(false)}
        insertTextAtCursor={insertTextAtCursor}
      />
      <EmailsPadraoDialog
        open={openEmailsPadrao}
        closeDialogHandler={() => setOpenEmailPadrao(false)}
        onChangeTexto={onChangeTexto}
        setFormFields={setFormFields}
      />
      <form style={{ display: 'flex', flexDirection: 'column' }} ref={formRef}>
        <Grid24 xs={24} sm={21} style={{ marginBottom: '10px' }}>
          <InputField
            fullWidth
            isForm
            value={formFields?.emailDe}
            label="De*"
            name="emailDe"
            error={errorFields?.emailDe.error}
            errorMessage={errorFields?.emailDe.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHander}
            disabled
          />
        </Grid24>

        <Grid24 container>
          <Grid24 xs={24} sm={21}>
            { !isAcaoEmMassa
            && (
            <Grid24 xs={24} sm={24} style={{ marginBottom: '10px' }}>
              <InputField
                fullWidth
                isForm
                value={formFields?.emailPara}
                label="Para*"
                name="emailPara"
                error={errorFields.emailPara.error}
                errorMessage={errorFields.emailPara.errorMessage}
                onChange={onChangeHandler}
                onFocus={onFocusHander}
              />
            </Grid24>
            )
          }
            <Grid24 xs={24} sm={24} style={{ marginBottom: '10px' }}>
              <InputField
                fullWidth
                isForm
                value={formFields?.emailCopia}
                label="Cópia"
                name="emailCopia"
                error={errorFields?.emailCopia.error}
                errorMessage={errorFields?.emailCopia.errorMessage}
                onChange={onChangeHandler}
                onFocus={onFocusHander}
              />
            </Grid24>
          </Grid24>
          { !isAcaoEmMassa
          && (
          <Grid24
            xs={24}
            sm={2}
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '22px', marginTop: '10px',
            }}
          >
            <ButtonInverterParaCopia onClick={handleInverterParaCopia}>
              <ImportExportIcon />
            </ButtonInverterParaCopia>
          </Grid24>
          )
}
        </Grid24>
        <Grid24 xs={24} sm={21} style={{ marginBottom: '10px' }}>
          <InputField
            fullWidth
            isForm
            value={formFields?.respostaPara}
            label="Resposta para:"
            name="respostaPara"
            error={errorFields?.respostaPara.error}
            errorMessage={errorFields?.respostaPara.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHander}
          />
        </Grid24>
        <Grid24 xs={24} sm={21} style={{ marginBottom: '10px' }}>
          <InputField
            fullWidth
            isForm
            value={formFields?.assunto}
            label="Assunto*"
            name="assunto"
            error={errorFields?.assunto.error}
            errorMessage={errorFields?.assunto.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHander}
          />
        </Grid24>
        <Grid24 xs={24} sm={21}>
          <InputFileIcon
            isForm
            acceptAll
            label="Anexos"
            name="anexos"
            labelName="anexos"
            value={formFields?.anexos}
            onChange={onChangeHandler}
            setFile={setAnexos}
            multiFile
          />
        </Grid24>
        <div style={{ display: 'flex', justifyContent: 'end', width: '90%' }}>
          <span style={{
            fontSize: '11px',
            color: '#707070',
            marginTop: '3px',
          }}
          >
            (.pdf, .png, .jpeg ou .jpg/máx: 10MB)
          </span>
        </div>
        <Grid24 xs={24} sm={24}>
          <EditorTexto
            placeholder="Insira um Texto"
            readOnly={!!isAcaoEmMassa}
            name="texto"
            label="Texto*"
            isInputLabel
            value={textoValue}
            onChange={onChangeTexto}
            model={errorFields.texto.error}
            onFocus={onFocusTexto}
            errorMessage={errorFields.texto.errorMessage}
            quillRef={editorRef}
          />
        </Grid24>
        <FooterContainer>
          <TextoPadraoButton
            variant="outlined"
            style={{ width: '133px', height: '30px', padding: '0' }}
            onClick={() => setOpenEmailPadrao(true)}
          >
            <LuMails />
            <span>Emails Padrão</span>
          </TextoPadraoButton>
          <TextoPadraoButton
            variant="outlined"
            style={{
              marginLeft: '10px', width: '133px', height: '30px', padding: '0',
            }}
            onClick={() => setOpenAutoTextoDialog(true)}
            disabled={!!isAcaoEmMassa}
          >

            <MdKeyboardDoubleArrowLeft />
            <MdKeyboardDoubleArrowRight />
            <span>Auto Texto</span>
          </TextoPadraoButton>
          <TextoPadraoButton
            variant="outlined"
            onClick={openMelhorarTextoDialog}
            style={{
              marginLeft: '10px', width: '133px', height: '30px', padding: '0',
            }}
            disabled={isAcaoEmMassa ? true : desabilitarReescrever}
          >
            <MdLibraryBooks />
            <span>Reescrever</span>
          </TextoPadraoButton>
          <TooltipUI style={{ marginTop: '4px', marginLeft: '8px' }} title="São necessárias no mínimo 5 palavras para reescrever o texto.">
            <LightIndicator><MdLightbulbOutline /></LightIndicator>
          </TooltipUI>
          <DialogGPT
            open={showDialogTest}
            onClose={() => setShowDialogTest(false)}
            initialText={textoValue}
            updateText={updateDescricaoAtividades}
          />
          { !isAcaoEmMassa
          && (
          <>
            <TextoPadraoButton
              variant="outlined"
              onClick={() => setOpenDialogAntecedentes(true)}
              style={{ marginRight: '10px', marginLeft: 'auto' }}
              disabled={disabledAntecedentes}
            >
              <MdLibraryBooks />
              <span>Antecedentes</span>
            </TextoPadraoButton>
            <ButtonUI
              onClick={() => setOpenDialogConfirmarEnvio(true)}
              color="success"
              style={{ width: '136px' }}
              disabled={disableEnviar}
            >
              Enviar
            </ButtonUI>
          </>
          )
        }
        </FooterContainer>
      </form>
    </>

  );
}


export default EnviarEmailDevedor;
