import { CORE, gateway } from '../../api';


export function buscarAutoTextos() {
  return gateway.get(`${CORE}/processos/devedor/email/auto-texto`);
}

export function buscarEmailsPadrao() {
  return gateway.get(`${CORE}/processos/devedor/email/emails-padrao`);
}

export function buscarInfoInicialLimparEnvioEmail(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/devedor/email/limpar`);
}

export function submitFormEnvioEmailDevedor(idProcesso, formFields, anexos) {
  const data = new FormData();

  anexos.forEach((anexo) => {
    data.append('anexos', anexo);
  });

  data.append('emailDe', formFields.emailDe);
  data.append('emailPara', formFields.emailPara);
  data.append('emailCopia', formFields.emailCopia);
  data.append('respostaPara', formFields.respostaPara);
  data.append('assunto', formFields.assunto);
  data.append('nomeModelo', formFields.nomeModelo);
  data.append('texto', formFields.texto);


  return gateway.post(`${CORE}/processos/${idProcesso}/devedor/email`, data, {
    'Content-Type': 'multipart/form-data',
  });
}
export function submitFormEnvioEmailEmMassa(idsProcessos, formFields, anexos) {
  const data = new FormData();

  anexos.forEach((anexo) => {
    data.append('dadosEmailDevedorCommand.anexos', anexo);
  });

  data.append('dadosEmailDevedorCommand.emailDe', formFields.emailDe);
  data.append('dadosEmailDevedorCommand.emailPara', formFields.emailPara);
  data.append('dadosEmailDevedorCommand.emailCopia', formFields.emailCopia);
  data.append('dadosEmailDevedorCommand.respostaPara', formFields.respostaPara);
  data.append('dadosEmailDevedorCommand.assunto', formFields.assunto);
  data.append('dadosEmailDevedorCommand.nomeModelo', formFields.nomeModelo);
  data.append('dadosEmailDevedorCommand.texto', formFields.texto);
  data.append('idProcessoList', idsProcessos);

  return gateway.post(`${CORE}/processos/devedor/email/processos`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
