import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';
import {
  buildParametros,
  changeEditModeAction,
  onChangeActionsAction,
  setAlertDialogValuesAction,
  setFileConfigPagto,
  setImageAction,
  setLoadingAction,
  updateRequestFieldsAction,
} from './crudParametros.store';

import {
  buildPayload,
  buildPayloadArray,
  getExceptionErrorMessage,
  hasMessageInException,
  isConflictException,
  isNotFoundException,
} from '../../../helpers/utils/utils';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import { buildEnderecoPayload } from '../../../helpers/factory/endereco.factory';
import {
  getParametros,
  insertParametros,
  obterArquivoQuitacaoDevedor,
  obterLogotipo,
  saveLogotipo,
  saveQuitacaoDevedorFile,
} from '../../../services/core/parametro/parametro.services';
import { GET_PARAMETROS, INSERT_PARAMETROS } from './crudParametros.constants';


export const insertParametrosAction = () => ({
  type: INSERT_PARAMETROS,
});

export const getParametrosAction = () => ({
  type: GET_PARAMETROS,
});


function* obterLogotipoHandler() {
  const image = yield select(state => state.image);
  const newImage = { ...image };
  try {
    const response = yield call(obterLogotipo);

    yield newImage.imagePreviewUrl = `data:image;base64,${response.data}`;
    yield put(setImageAction(newImage));
  } catch (e) {
    // Nenhum tratamento até agora...
  }
}

function* obterArquivoQuitacaoDevedorHandler() {
  const filename = yield select(state => state.requestFields.arquivoDevedorQuitacao.value);

  try {
    const response = yield call(obterArquivoQuitacaoDevedor);
    const base64String = response.data;

    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    const file = new File([blob], filename, { type: 'application/octet-stream' });

    yield put(setFileConfigPagto(file));
  } catch (e) {
    // sem tratativa
  }
}


function* getParametrosHandler() {
  yield put(setLoadingAction(true));
  const parametros = yield getParametros();
  yield put(buildParametros(parametros.data));
  yield obterLogotipoHandler();
  yield obterArquivoQuitacaoDevedorHandler();
  yield put(setLoadingAction(false));
}

function* successSubmit() {
  const isEditMode = yield select(states => states.pageView.isEditMode);
  yield put(changeEditModeAction(!isEditMode));
}

function* catchSubmitException(exception, requestFields) {
  const [updatedRequestFields] = yield getExceptionHandler(exception, requestFields);
  yield put(changeEditModeAction(true));

  const exceptionMessage = getExceptionErrorMessage(exception);
  const conflictException = isConflictException(exception);
  const notFoundException = isNotFoundException(exception);
  const badRequestExceptionMessage = hasMessageInException(exception);

  if (updatedRequestFields && !badRequestExceptionMessage) {
    toast.error(exceptionMessage, { style: { width: '392px' } });
    yield put(updateRequestFieldsAction(updatedRequestFields));
  } else if (badRequestExceptionMessage) {
    if (exception.response.data.path === '/parametros/foto') {
      yield put(onChangeActionsAction('errorImage', true));
      yield put(setAlertDialogValuesAction(true, 'warning', badRequestExceptionMessage, 'reload'));
    } else {
      toast.error(badRequestExceptionMessage, { style: { width: '392px' } });
    }
  }

  if (yield (notFoundException || conflictException)) {
    yield put(setAlertDialogValuesAction(true, 'error', exceptionMessage, 'reload'));
  }
}

function buildParametroHonorariosPayload(requestFields, tipoHonorario, idx) {
  const returnedTipoHonorario = { ...tipoHonorario };
  returnedTipoHonorario.honorarios = buildPayloadArray(requestFields.tiposCalculoHonorarios[idx].honorarios);
  return returnedTipoHonorario;
}

function buildParametroComissoesPayload(requestFields, tipoComissao, idx) {
  const returnedTipoComissao = { ...tipoComissao };
  returnedTipoComissao.comissoes = buildPayloadArray(requestFields.tiposCalculoComissoes[idx].comissoes);
  return returnedTipoComissao;
}

function getParametrosForPayload(requestFields) {
  const parametrosPayload = buildPayload(requestFields);
  parametrosPayload.endereco = buildEnderecoPayload(requestFields.endereco);
  parametrosPayload.telefones = buildPayloadArray(requestFields.telefones);
  parametrosPayload.jurosEhRepasse = buildPayloadArray(requestFields.jurosEhRepasse);
  parametrosPayload.descontoBoletos = buildPayloadArray(requestFields.descontoBoletos);
  parametrosPayload.descontoPix = buildPayloadArray(requestFields.descontoPix);
  parametrosPayload.taxaCartao = buildPayloadArray(requestFields.taxaCartao);
  parametrosPayload.contaBancariaBoletoCliente = buildPayload(requestFields.contaBancariaBoletoCliente);
  parametrosPayload.contaBancariaBoletoDevedor = buildPayload(requestFields.contaBancariaBoletoDevedor);
  parametrosPayload.contaBancariaSispag = buildPayload(requestFields.contaBancariaSispag);
  parametrosPayload.contasBancariasDepositoClientes = buildPayloadArray(requestFields.contasBancariasDepositoClientes);
  parametrosPayload.contasBancariasPagamentosGerais = buildPayloadArray(requestFields.contasBancariasPagamentosGerais);


  const tiposCalculoHonorarios = buildPayloadArray(requestFields.tiposCalculoHonorarios);
  const tiposCalculoComissoes = buildPayloadArray(requestFields.tiposCalculoComissoes);

  parametrosPayload.tiposCalculoHonorarios = tiposCalculoHonorarios
    .map((tipoHonorario, idx) => {
      if ((requestFields.tipoHonorarioSelecionado.value === 1 && tipoHonorario.type === 'DIAS')
      || (requestFields.tipoHonorarioSelecionado.value === 2 && tipoHonorario.type === 'MONEY')) {
        return buildParametroHonorariosPayload(requestFields, tipoHonorario, idx);
      }
      tipoHonorario.honorarios = [];
      return { ...tipoHonorario };
    });

  parametrosPayload.tiposCalculoComissoes = tiposCalculoComissoes
    .map((tipoComissao, idx) => {
      if ((requestFields.tipoComissaoSelecionada.value === 1 && tipoComissao.type === 'DIAS')
      || (requestFields.tipoComissaoSelecionada.value === 2 && tipoComissao.type === 'MONEY')) {
        return buildParametroComissoesPayload(requestFields, tipoComissao, idx);
      }
      tipoComissao.comissoes = [];
      return { ...tipoComissao };
    });

  return parametrosPayload;
}

function* submit(promise) {
  const requestFields = yield select(states => states.requestFields);
  try {
    yield put(setLoadingAction(true));
    const payload = yield getParametrosForPayload(requestFields);
    yield promise(payload);

    const foto = yield select(states => states.image);
    if (requestFields.idParametro && foto.file) {
      yield saveLogotipo(foto.file);
    }

    const quitacaoDevedorFile = yield select(states => states.quitacaoDevedorFile);
    if (requestFields.idParametro) {
      yield saveQuitacaoDevedorFile(quitacaoDevedorFile);
    }

    yield successSubmit();
  } catch (exception) {
    yield catchSubmitException(exception, requestFields);
  } finally {
    yield put(setLoadingAction(false));
  }
}


function* insertParametrosHandler() {
  yield submit(insertParametros);
}

export default function* watchCrudParametros() {
  yield takeLatest(GET_PARAMETROS, getParametrosHandler);
  yield takeLatest(INSERT_PARAMETROS, insertParametrosHandler);
}
